import React from 'react';

import MuiCheckbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useField } from 'formik';
import { ValidationIcon } from './ValidationIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '4px',
    color: 'transparent',
    background: theme.palette.common.white,
    borderRadius: '4px',
    border: `2px solid ${theme.palette.grey[500]}`
  },
  rootSelected: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: '4px',
    border: `none`,
    padding: '1px'
  },
  rootSelectedSuccess: {
    padding: '4px',
    color: '#999999',
    background: '#0BF940',
    borderRadius: '4px'
  },
  fontSizeSmall: {
    fontSize: '16px'
  },
  fontSizeSmallSuccess: {
    fontSize: '24px',
    color: theme.palette.common.white,
    fontWeight: 500
  },
  formControlLabelRoot: {
    marginRight: 0,
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      padding: '4px 12px'
    }
  },
  label: {
    fontSize: theme.spacing(1.4),
    color: theme.palette.grey[700],
    fontWeight: 400
  }
}));

interface CheckboxProps {
  name: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  success?: boolean;
  muiStyle?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    name,
    label,
    checked,
    success,
    muiStyle,
    onChange,
    disabled = false
  } = props;

  const [field, meta, helpers] = useField(name);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <MuiCheckbox
            disabled={disabled}
            color="primary"
            icon={
              !muiStyle ? (
                <CheckBoxOutlineBlankIcon
                  fontSize="small"
                  classes={{
                    root: classes.root,
                    fontSizeSmall: classes.fontSizeSmall
                  }}
                />
              ) : undefined
            }
            onBlur={(e) => {
              field.onBlur(e);
              helpers.setTouched(true);
            }}
            checkedIcon={
              !muiStyle ? (
                <CheckIcon
                  fontSize="small"
                  classes={{
                    root: !success
                      ? classes.rootSelected
                      : classes.rootSelectedSuccess,
                    fontSizeSmall: !success
                      ? classes.fontSizeSmall
                      : classes.fontSizeSmallSuccess
                  }}
                />
              ) : undefined
            }
            name={field.name}
            checked={checked ? checked : field.value}
            onChange={handleChange}
          />
        }
        label={label ? label : ''}
        classes={{ label: classes.label, root: classes.formControlLabelRoot }}
      />
      {meta.error && meta.touched && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <ValidationIcon />
          <FormHelperText error>{meta.error}</FormHelperText>
        </Box>
      )}
    </div>
  );
};

export { Checkbox };
