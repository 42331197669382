import { FC } from 'react';
import { Box } from '@material-ui/core';

const Content: FC = ({ children }) => {
  return (
    <Box paddingX={2} paddingY={2.4}>
      {children}
    </Box>
  );
};

export { Content };
