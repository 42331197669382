import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { debounce } from 'lodash';

import { convertToINR } from '../../utils';
import { BasicTextInput } from './basic';
import { useResponsive } from '../../theme/styles';
import { FormHelperText, Hidden } from '@material-ui/core';
import { closestMultiple } from '../../utils/calculations';
import { ValidationIcon } from './ValidationIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(1.5),
      lineHeight: 1
    },
    seeker: {},
    margin: {
      height: theme.spacing(3)
    },
    coversContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2)
    },
    incButtonRoot: {
      background: '#F7F9FA',
      maxWidth: 82,
      border: 'none',
      '&:hover': {
        background: '#F7F9FA',
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)'
      },
      '@media(max-width:360px)': {
        maxWidth: 65
      }
    },
    incButtonDisabled: {
      background: '#F7F9FA',
      border: 'none !important',
      '& .MuiButton-label': {
        color: 'rgba(0, 0, 0, 0.26) !important'
      },
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.26) !important'
      }
    },
    incButtonLabel: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: '11px',
        lineHeight: '16px'
      }
    },
    addIconRoot: {
      background: 'white',
      borderRadius: '50%',
      color: theme.palette.primary.main
    },
    coverLabel: {
      width: theme.spacing(16),
      background: '#F7F9FA',
      borderRadius: 8,
      height: theme.spacing(4.4),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(9),
        height: theme.spacing(3.8)
      }
    }
  })
);

interface CoverChangeButtonProps {
  value: number;
  currentCover: number;
  min: number;
  max: number;
  disabled?: boolean;
  onClick: (modVal: number) => void;
}

const CoverChangeButton: React.FC<CoverChangeButtonProps> = React.memo(
  ({ value, currentCover, min, max, disabled, onClick }) => {
    const classes = useStyles();
    const isDisabled =
      disabled ||
      (value < 0 && currentCover < Math.abs(value)) ||
      (value < 0 && currentCover <= min) ||
      (value > 0 && currentCover >= max);
    return (
      <Button
        variant="outlined"
        color="default"
        fullWidth
        classes={{
          root: classes.incButtonRoot,
          label: classes.incButtonLabel,
          disabled: classes.incButtonDisabled
        }}
        startIcon={
          value < 0 ? (
            <RemoveIcon classes={{ root: classes.addIconRoot }} />
          ) : undefined
        }
        endIcon={
          value > 0 ? <AddIcon classes={{ root: classes.addIconRoot }} /> : undefined
        }
        disabled={isDisabled}
        onClick={() => onClick(value)}
      >
        {convertToINR(Math.abs(value), 2)}
      </Button>
    );
  }
);

interface CoverPickerProps {
  title?: string;
  subTitle?: string;
  name: string;
  max: number;
  min: number;
  value: number;
  disabled?: boolean;
  refresh?: boolean;
  onChange: (value: number) => void;
}

const CoverPicker: React.FC<CoverPickerProps> = React.memo(
  ({
    title,
    subTitle,
    name,
    max,
    min,
    value,
    disabled,
    refresh,
    onChange
  }: CoverPickerProps) => {
    const r = useResponsive();
    const classes = useStyles();
    const [localValue, setLocalValue] = useState<number>(value ? value : min);
    const isTextFocussed = useRef<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
      if (refresh) {
        setLocalValue(value);
      }
    }, [refresh]);

    useEffect(() => {
      setLocalValue(value ? value : min);
    }, [value]);

    useEffect(() => {
      // message is empty (meaning no errors). Adjust as needed
      if (error === '') {
        return;
      }
      // error exists. Display the message and hide after 5 secs
      const timer = setTimeout(() => {
        setError('');
      }, 5000);
      return () => clearTimeout(timer);
    }, [error]);

    const debouncedOnChange = useRef(
      debounce((currValue: number) => {
        onChange(currValue);
      }, 1000)
    );

    const handleFocus = () => {
      isTextFocussed.current = true;
    };

    const sanitizeValue = (input: any) => {
      if (!input || input === '') {
        return 0;
      } else if (typeof input === 'number') {
        return input;
      } else if (typeof input === 'string') {
        return parseInt(input.replace(/,/g, ''));
      } else {
        return input;
      }
    };

    const handleBlur = (input: any) => {
      let finalVal = min;
      const val = sanitizeValue(input);

      if (val <= min) {
        finalVal = min;
        setError(
          `You can’t pick a value less than 50 lakhs because most insurers don’t allow for it`
        );
      } else if (val >= max) {
        finalVal = max;
        setError(
          `Maximum cover allowed for your profile is ${convertToINR(max, 2, true)}`
        );
      } else {
        setError('');
        finalVal = val;
      }
      // find the closesest multiple of 5L
      finalVal = closestMultiple(finalVal, 500000);
      if (value !== localValue) {
        setLocalValue(finalVal);
        debouncedOnChange.current(finalVal);
      }
      isTextFocussed.current = false;
    };

    const handleChange = (event: React.ChangeEvent<any> | null, value: number) => {
      let finalVal = value;
      if (typeof value === 'number') {
        finalVal = value;
      } else if (typeof value === 'string') {
        if (value === '') {
          finalVal = 0;
        }
      }
      setLocalValue(finalVal);
      if (!isTextFocussed.current) {
        debouncedOnChange.current(finalVal);
      }
    };

    const modifyCover = (modValue: number) => {
      let finalCover = localValue + modValue;
      if (finalCover > max) {
        setError(
          `Maximum cover allowed for your profile is ${convertToINR(max, 2, true)}`
        );
        finalCover = max;
      } else if (finalCover < min) {
        setError(
          `You can’t pick a value less than 50 lakhs because most insurers don’t allow for it`
        );
        finalCover = min;
      }
      setLocalValue(finalCover);
      debouncedOnChange.current(finalCover);
    };

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item md={8} sm={8} xs={8}>
            <Typography
              id={`cover-picker-${name}`}
              variant="body1"
              color="textPrimary"
            >
              <Box fontWeight="fontWeightMedium" component="span">
                {title}
              </Box>
            </Typography>
            <Box marginTop={0.6}>
              <Typography variant="body2" color="textSecondary">
                {subTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
            >
              <Box className={classes.coverLabel}>
                <Typography variant="body2" color="textPrimary">
                  <Box component="span" fontWeight="600">
                    {convertToINR(localValue, 2)}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Hidden smDown>
          <div className={classes.coversContainer}>
            <CoverChangeButton
              value={-5000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <CoverChangeButton
              value={-1000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <Box
              maxWidth={r({ xs: 112, sm: 150, md: 160 })}
              height="100%"
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <BasicTextInput
                name={`cover-picker-input-${name}`}
                typeNumber
                onChange={(val) => handleChange(null, val)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={localValue}
                placeholder=""
                marginTop={0}
                centerText
                disabled={disabled}
                moneyAdornment
              />
            </Box>
            <CoverChangeButton
              value={1000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <CoverChangeButton
              value={5000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <Box
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            marginTop={2}
          >
            <BasicTextInput
              name={`cover-picker-input-${name}`}
              typeNumber
              onChange={(val) => handleChange(null, val)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              value={localValue}
              placeholder=""
              marginTop={0}
              centerText
              disabled={disabled}
              moneyAdornment
            />
          </Box>
          <div className={classes.coversContainer}>
            <CoverChangeButton
              value={-5000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <CoverChangeButton
              value={-1000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <CoverChangeButton
              value={1000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
            <CoverChangeButton
              value={5000000}
              currentCover={localValue}
              min={min}
              max={max}
              onClick={modifyCover}
              disabled={disabled}
            />
          </div>
        </Hidden>
        {error !== '' && (
          <Box display="flex" flexDirection="row" alignItems="center" marginTop={1}>
            <ValidationIcon />
            <FormHelperText>{error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { CoverPicker };
