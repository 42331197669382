import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accRoot: {
    boxShadow: 'none',
    border: 'none',
    borderRadius: '12px !important',
    '&:before': {
      backgroundColor: 'transparent'
    },
    '&::last-child': {
      borderRadius: '12px !important'
    }
  },
  accSumRoot: {
    minHeight: '40 !important',
    height: '40 !important'
  },
  accSumContent: {
    margin: 0
  },
  accSumExp: {
    minHeight: '40 !important',
    height: '40 !important'
  }
}));

interface TermsAccordionProps {
  summary: any;
  details: any;
}

const TermsAccordion: React.FC<TermsAccordionProps> = ({
  summary,
  details
}) => {
  const classes = useStyles();
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: classes.accSumRoot,
          content: classes.accSumContent,
          expanded: classes.accSumExp
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export { TermsAccordion };
