import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { useResponsive } from '../../theme/styles';

export const FormRow: React.FC<GridProps> = React.memo((props) => {
  const r = useResponsive();
  const { children, ...restProps } = props;
  return (
    <Grid container item spacing={r({ xs: 1, sm: 2, md: 2 })} {...restProps}>
      {children}
    </Grid>
  );
});
