import { Chip, makeStyles } from '@material-ui/core';
import { lowerCase, startCase } from 'lodash';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  sizeSmall: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto',
    paddingRight: 8,
    paddingLeft: 8
  }
}));

interface TableLabelProps {
  label: string;
}

const TableLabel: FC<TableLabelProps> = ({ label }) => {
  const classes = useStyles();

  let type = '';

  switch (label) {
    case 'ISSUED':
      type = 'success';
      break;
    case 'IN PROCESS':
      type = 'warning';
      break;
    case 'PAYMENT DONE':
      type = 'info';
      break;
    case 'REJECTED':
      type = 'error';
      break;
    case 'CANCELLED':
      type = 'error';
      break;
  }

  const getColors = () => {
    let bgColor = '#FEF4E2';
    let iconColor = '#966316';

    if (type === 'warning') {
      bgColor = '#FFF6DC';
      iconColor = '#A87E03';
    } else if (type === 'success') {
      bgColor = '#DBFBE3';
      iconColor = '#039D69';
    } else if (type === 'info') {
      bgColor = '#D8E6FF';
      iconColor = '#2962FF';
    } else if (type === 'error') {
      bgColor = '#FFE7E7';
      iconColor = '#A50000';
    }

    return { bgColor, iconColor };
  };

  const colors = getColors();

  return (
    <Chip
      classes={{
        sizeSmall: classes.sizeSmall
      }}
      size="small"
      style={{ background: colors.bgColor, color: colors.iconColor }}
      label={startCase(lowerCase(label))}
    />
  );
};

export { TableLabel };
