import React from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useField } from 'formik';

import { ValidationIcon } from './ValidationIcon';
import { Switch } from './Switch';
import { Typography } from '@material-ui/core';
import { useResponsive } from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  bullet: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    marginRight: 12,
    marginTop: 7,
    flexShrink: 0
  }
}));

interface Option {
  label: string;
  value: string | number | boolean;
  labelRight?: string;
}

interface SwitcherQuestionProps {
  name: string;
  options: [Option, Option];
  label: string;
  disabled?: boolean;
  showBullet?: boolean;
  useSwitchAlways?: boolean;
  questionColumnWidth?: GridSize;
  marginTop?: number;
  onChange?: (value?: boolean) => void;
}

const SwitcherQuestion: React.FC<SwitcherQuestionProps> = React.memo(
  ({
    name,
    options,
    label,
    disabled,
    showBullet,
    useSwitchAlways,
    questionColumnWidth,
    marginTop,
    onChange
  }: SwitcherQuestionProps) => {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const r = useResponsive();

    return (
      <div
        className={classes.textFieldContainer}
        style={marginTop ? { marginTop } : undefined}
      >
        <Grid container>
          <Grid
            item
            md={!questionColumnWidth ? 9 : questionColumnWidth}
            sm={!questionColumnWidth ? 9 : questionColumnWidth}
            xs={!useSwitchAlways && !disabled ? 12 : 8}
          >
            <Box display="flex" flexDirection="row">
              {showBullet && <div className={classes.bullet} />}
              <Typography variant="body2" color="textPrimary">
                {label}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={
              !questionColumnWidth
                ? 3
                : ((12 - (questionColumnWidth as number)) as GridSize)
            }
            sm={
              !questionColumnWidth
                ? 3
                : ((12 - (questionColumnWidth as number)) as GridSize)
            }
            xs={!useSwitchAlways && !disabled ? 12 : 4}
          >
            <Box
              display="flex"
              flexDirection="row"
              marginLeft={r({
                md: 0,
                sm: 0,
                xs: !useSwitchAlways ? 1.8 : 0
              })}
              marginTop={r({
                md: 0,
                sm: 0,
                xs: !useSwitchAlways && !disabled ? 1.6 : 0
              })}
              justifyContent={r({
                md: 'flex-end',
                sm: 'flex-end',
                xs: !useSwitchAlways && !disabled ? 'flex-start' : 'flex-end'
              })}
            >
              <Switch
                options={options}
                onChange={(value) => {
                  helpers.setValue(value);
                  helpers.setTouched(true, false);
                  onChange &&
                    onChange(
                      value !== undefined
                        ? value === options[1].value
                        : undefined
                    );
                }}
                checked={
                  field.value !== undefined
                    ? field.value === options[1].value
                    : undefined
                }
                disabled={disabled}
                useSwitchAlways={useSwitchAlways}
              />
            </Box>
          </Grid>
        </Grid>
        {meta.error && meta.touched && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { SwitcherQuestion };
