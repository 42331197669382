import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useField } from 'formik';
import { ValidationIcon } from './ValidationIcon';

import { Switch } from './Switch';
import { useResponsive } from '../../theme/styles';
import { find } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  label: {
    color: theme.palette.grey[800]
  },
  bullet: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    marginRight: 12,
    marginTop: 7
  }
}));

interface Option {
  label: string;
  value: string | number;
  labelRight?: string;
}

interface MultiSelectSwitchesProps {
  name: string;
  options: Option[];
  label: string;
  showBullet?: boolean;
  singleSelect?: boolean;
  parentQuestionName: string;
  onChange?: (value: Record<string, unknown>) => void;
}

const MultiSelectSwitches: React.FC<MultiSelectSwitchesProps> = React.memo(
  ({
    name,
    options,
    label,
    showBullet,
    parentQuestionName,
    singleSelect = false,
    onChange
  }: MultiSelectSwitchesProps) => {
    const [field, meta, helpers] = useField(name);
    const classes = useStyles();
    const r = useResponsive();

    const parentQuestionOption: Option | undefined = find(
      options,
      (o) => o.value === parentQuestionName
    );

    const isParentOptionSelected = parentQuestionOption
      ? singleSelect
        ? parentQuestionOption.value === field.value
        : field.value && Object.keys(field.value).length
        ? field.value[parentQuestionOption.value]
        : undefined
      : undefined;

    const othereQuestionOptions = options.filter((o) => {
      return o.value !== parentQuestionName;
    });

    const renderRow = (index: number, option: Option) => {
      const isLast = index === othereQuestionOptions.length - 1;
      const isSelected = singleSelect
        ? option.value === field.value
        : field.value && Object.keys(field.value).length
        ? field.value[option.value]
        : undefined;
      return (
        <Box
          key={index}
          paddingBottom={1.8}
          borderBottom={!isLast ? '1px solid #E8EAEB' : 'none'}
          marginBottom={!isLast ? 1.8 : 0}
        >
          <Grid container>
            <Grid item md={9} sm={12} xs={12}>
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" className={classes.label}>
                  {option.label}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Box
                display="flex"
                justifyContent={r({
                  md: 'flex-end',
                  sm: 'flex-end',
                  xs: 'flex-start'
                })}
              >
                <Switch
                  name={option.value}
                  options={[
                    { label: 'No', value: false },
                    {
                      label: 'Yes',
                      value: true
                    }
                  ]}
                  returnType="boolean"
                  onChange={(checked: any) => {
                    helpers.setTouched(true, false);
                    let value = field.value;
                    if (singleSelect) {
                      value = option.value;
                    } else if (parentQuestionOption) {
                      if (checked) {
                        value = {
                          ...field.value,
                          [option.value]: checked,
                          [parentQuestionOption.value]: true
                        };
                      } else {
                        value = {
                          ...field.value,
                          [option.value]: checked
                        };
                      }
                    }
                    helpers.setValue(value);
                    onChange && onChange(value);
                  }}
                  checked={isSelected}
                  disabled={false}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    };

    return (
      <div className={classes.textFieldContainer}>
        <Box marginBottom={2.4}>
          <Box
            display="flex"
            flexDirection="row"
            borderBottom={r({
              md: 'none',
              sm: 'none',
              xs: '1px solid #E8EAEB'
            })}
          >
            <Grid container>
              <Grid item md={9} sm={9} xs={12}>
                <Box display="flex" flexDirection="row">
                  {showBullet && <div className={classes.bullet} />}
                  <Typography variant="body2" color="textPrimary">
                    {label}
                  </Typography>
                </Box>
              </Grid>
              {parentQuestionOption && (
                <Grid item md={3} sm={3} xs={12}>
                  <Box
                    display="flex"
                    justifyContent={r({
                      md: 'flex-end',
                      sm: 'flex-end',
                      xs: 'flex-start'
                    })}
                    marginLeft={1.8}
                  >
                    <Switch
                      options={[
                        { label: 'No', value: false },
                        {
                          label: 'Yes',
                          value: true
                        }
                      ]}
                      returnType="boolean"
                      onChange={(checked: any) => {
                        helpers.setTouched(true, false);
                        let value = field.value;
                        if (singleSelect) {
                          value = parentQuestionOption.value;
                        } else {
                          if (checked) {
                            value = {
                              ...field.value,
                              [parentQuestionOption.value]: checked
                            };
                          } else {
                            const finalValue: any = {};
                            Object.keys(field.value).map((key, index) => {
                              if (key !== parentQuestionOption.value) {
                                finalValue[key] = false;
                              }
                            });
                            value = {
                              ...finalValue,
                              [parentQuestionOption.value]: checked
                            };
                          }
                        }
                        helpers.setValue(value);
                        onChange && onChange(value);
                      }}
                      checked={isParentOptionSelected}
                      disabled={false}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box paddingLeft={2.4}>
          {othereQuestionOptions.map((option: Option, index: number) => {
            return renderRow(index, option);
          })}
        </Box>
        {meta.error && meta.touched && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { MultiSelectSwitches };
