import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import MuiButton, {
  ButtonProps as MuiButtonProps
} from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
  contained: {
    '&:disabled': {
      color: 'white',
      background: '#B0D9F4'
    }
  },
  circularProgress: {
    marginLeft: theme.spacing(1),
    color: 'white'
  },
  circularProgressBlack: {
    marginLeft: theme.spacing(1),
    color: '#dadada'
  }
}));

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  customBg?: string;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles();
  const { loading, children, disabled, variant, customBg, ...rest } = props;
  return (
    <MuiButton
      {...rest}
      classes={{
        contained: classes.contained
      }}
      disabled={disabled || loading}
      variant={variant}
      style={customBg ? { background: `${customBg}` } : undefined}
    >
      {!loading ? (
        children
      ) : (
        <CircularProgress
          className={
            variant !== 'outlined'
              ? classes.circularProgress
              : classes.circularProgressBlack
          }
          size="1.5rem"
        />
      )}
    </MuiButton>
  );
};

Button.defaultProps = {
  loading: false
};

export { Button };
