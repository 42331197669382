import React from 'react';
import Container from '@material-ui/core/Container';

import { useStyles } from '../theme/styles';

const AuthLayout: React.FC = (props) => {
  const classes = useStyles();
  return (
    <>
      <Container classes={{ root: classes.authContainerRoot }} maxWidth={false}>
        {props.children ? props.children : ''}
      </Container>
    </>
  );
};

export { AuthLayout };
