import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from 'formik';
import { find, startCase, toLower } from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    fontSize: 16
  },
  input: {
    padding: theme.spacing(0)
  },
  inputRoot: {
    background: theme.palette.common.white,
    '&.MuiOutlinedInput-root': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '12px',
        paddingBottom: '12px'
      }
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      background: '#F7F9FA !important',
      color: '#BFC4C6 !important'
    }
  },
  textFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  autoCompleteRoot: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(3)
  },
  autoCompleteFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important',
    padding: theme.spacing(0)
  }
}));

interface AutoCompleteOption {
  value: string | number | boolean;
  label: string;
  customOption?: boolean | undefined;
}

interface AutocompleteProps {
  name: string;
  label: string;
  loading?: boolean;
  placeholder?: string;
  options: AutoCompleteOption[];
  helperText?: string;
  includeDynamicOption?: boolean;
  disabled?: boolean;
  startCasedOptions?: boolean;
  afterCustomSelection?: (value: string) => any;
  afterSelection?: (value: string) => any;
}

const filter = createFilterOptions<AutoCompleteOption>();

const AutoComplete: React.FC<AutocompleteProps> = React.memo(
  ({
    name,
    options,
    label,
    placeholder,
    helperText,
    includeDynamicOption,
    disabled,
    startCasedOptions = false,
    loading = false,
    afterCustomSelection,
    afterSelection
  }: AutocompleteProps) => {
    const classes = useStyles();
    const [focussed, setFocussed] = useState<boolean>(false);
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const { onBlur } = field;
    const currentValue = options.find((o) => o.value === field.value);

    const handleOnChange = (event: any, value: any, reason: string) => {
      if (value && includeDynamicOption && value.customOption) {
        setValue(value.value);
        afterCustomSelection && afterCustomSelection(value.value);
      } else if (value) {
        setValue(value.value);
        afterSelection && afterSelection(value.value);
      } else {
        setValue('');
      }
    };

    const handleOnBlur = (event: any) => {
      const inputValue = event?.target.value;
      const selectedOption = options.find(({ value }) => value === inputValue);
      if (selectedOption === undefined && includeDynamicOption) {
        setValue(inputValue);
        afterCustomSelection && afterCustomSelection(inputValue);
      }
      helpers.setTouched(true);
    };

    return (
      <MuiAutocomplete
        loading={loading}
        onBlur={handleOnBlur}
        id={`auto-complete-${name}`}
        options={options.map((option) =>
          !startCasedOptions
            ? option
            : { ...option, label: startCase(toLower(option.label)) }
        )}
        classes={{
          root: clsx(
            classes.autoCompleteRoot,
            currentValue && !meta.error && !focussed
              ? classes.autoCompleteFilled
              : null
          ),
          inputRoot: classes.inputRoot,
          input: !disabled ? classes.input : classes.textInputDisabled,
          option: classes.option
        }}
        disabled={disabled}
        autoHighlight
        fullWidth
        getOptionLabel={(option: AutoCompleteOption) => option.label}
        value={currentValue ? currentValue : null}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreIcon />}
        getOptionSelected={(option) =>
          typeof option.value === 'string'
            ? toLower(option.value) === toLower(field.value)
            : option.value === field.value
        }
        {...(includeDynamicOption
          ? {
              filterOptions: (options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    value: params.inputValue,
                    label: params.inputValue,
                    customOption: true
                  });
                }
                return filtered;
              },
              freeSolo: true
            }
          : {})}
        renderInput={(params) => {
          return (
            <>
              <InputLabel
                classes={{
                  root: classes.textFieldLabel
                }}
                {...params.InputLabelProps}
              >
                {label}
              </InputLabel>
              <TextField
                autoComplete="off"
                placeholder={placeholder ? placeholder : label}
                onFocus={() => {
                  setFocussed(true);
                }}
                error={meta.error && meta.touched ? true : false}
                helperText={
                  meta.error && meta.touched
                    ? meta.error
                    : helperText
                    ? helperText
                    : ''
                }
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off', // disable autocomplete and autofill
                  ...(currentValue && {
                    value: !startCasedOptions
                      ? currentValue.label
                      : startCase(toLower(currentValue.label))
                  }) //added this to show value instantly if available,
                }}
                onChange={(event) => {
                  if (find(options, event.target.value)) {
                    setValue(event.target.value);
                  } else {
                    setValue('');
                  }
                }}
                onBlur={(event) => {
                  setFocussed(false);
                  onBlur(event);
                }}
              />
            </>
          );
        }}
      />
    );
  }
);

export { AutoComplete };
