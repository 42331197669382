import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormLoader } from '../loaders';

const useStyles = makeStyles(() => ({
  fieldSet: {
    border: 'none',
    padding: 0,
    '&:disabled .MuiSelect-root,&:disabled .MuiAutocomplete-inputRoot, &:disabled .MuiButtonBase-root, &:disabled .MuiInputBase-root,&:disabled .MuiOutlinedInput-input': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
      pointerEvents: 'none',
      borderColor: 'rgba(0, 0, 0, 0.38)'
    }
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
    zIndex: 2000,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface FormProps {
  formStyles?: any;
  loading: boolean;
  showLoader?: boolean;
}

const Form: React.FC<FormProps> = ({
  formStyles,
  loading,
  children,
  showLoader
}) => {
  const classes = useStyles();
  return (
    <form
      autoComplete="off"
      style={formStyles ? { ...formStyles } : { width: '100%' }}
    >
      {loading && showLoader && (
        <div className={classes.loaderContainer}>
          <FormLoader message="Loading..." />
        </div>
      )}
      <fieldset disabled={loading} className={classes.fieldSet}>
        {children}
      </fieldset>
    </form>
  );
};

export { Form };
