import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DittoMascot from '../../res/img/logoicon.png';

const useStyles = makeStyles((theme: Theme) => ({
  fieldInfoCircleMobile: {
    width: 'auto',
    height: 40,
    padding: theme.spacing(1)
  }
}));

interface FieldInfoMobileProps {
  subTitle?: string;
  children?: any;
  hideIcon?: boolean;
}

const FieldInfoMobile: React.FC<FieldInfoMobileProps> = ({
  hideIcon,
  subTitle,
  children
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      marginTop={2}
      borderRadius={8}
      padding={1}
      border="1px solid rgba(29, 201, 255, 0.3)"
      bgcolor="rgba(29, 201, 255, 0.05)"
    >
      {!hideIcon && (
        <img src={DittoMascot} className={classes.fieldInfoCircleMobile} />
      )}
      {subTitle ? (
        <Box marginLeft={1} minHeight="40px" display="flex" alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {subTitle}
          </Typography>
        </Box>
      ) : (
        <Box marginLeft={1}>{children}</Box>
      )}
    </Box>
  );
};

export { FieldInfoMobile };
