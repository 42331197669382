import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonRoot: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1 / 2),
      background: theme.palette.common.white,
      fontWeight: 600
    },
    counter: {
      color: theme.palette.primary.main,
      background: 'rgba(61,171,245,0.1)',
      fontSize: theme.spacing(1.6),
      fontWeight: 'bold',
      width: theme.spacing(4.2),
      height: theme.spacing(4.4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0
    },
    buttonRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(61,171,245,0.15)',
      height: 44,
      width: 48,
      '&:hover': {
        background: theme.palette.primary.main
      }
    },
    buttonLeft: {
      borderRight: '1px solid rgba(61,171,245,0.25)',
      borderRadius: '6px 0px 0px 6px'
    },
    buttonRight: {
      borderLeft: '1px solid rgba(61,171,245,0.25)',
      borderRadius: '0px 6px 6px 0px'
    }
  })
);

interface CounterProps {
  title?: string;
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
}

const Counter: React.FC<CounterProps> = React.memo(
  ({ title, value, onIncrement, onDecrement }) => {
    const classes = useStyles();
    return (
      <>
        {title && (
          <Box marginBottom={1.4}>
            <Typography variant="body2">{title}</Typography>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderRadius={4}
          height={44}
          width={136}
        >
          <IconButton
            classes={{ root: clsx(classes.buttonRoot, classes.buttonLeft) }}
            onClick={onDecrement}
          >
            <RemoveOutlinedIcon
              classes={{ root: classes.iconButtonRoot }}
              color="primary"
            />
          </IconButton>
          <div className={classes.counter}>{value}</div>
          <IconButton
            classes={{ root: clsx(classes.buttonRoot, classes.buttonRight) }}
            onClick={onIncrement}
          >
            <AddOutlinedIcon
              classes={{ root: classes.iconButtonRoot }}
              color="primary"
            />
          </IconButton>
        </Box>
      </>
    );
  }
);

export { Counter };
