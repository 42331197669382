import React from 'react';
import { toast } from 'react-toastify';
import { Box, Typography } from '@material-ui/core';
import { startCase } from 'lodash';
import InfoIcon from '@material-ui/icons/PriorityHigh';
import ErrorIcon from '@material-ui/icons/Clear';
import SuccessIcon from '@material-ui/icons/Check';

export const DittoToast = {
  success: (message?: string, title?: string) => {
    DittoToastGenerator('success', message, title);
  },
  info: (message: string, title?: string) => {
    DittoToastGenerator('info', message, title);
  },
  warning: (message: string, title?: string) => {
    DittoToastGenerator('warning', message, title);
  },
  error: (message: string, title?: string) => {
    DittoToastGenerator('error', message, title);
  }
};

const ToastContainer = (props: any) => <div>{props.children}</div>;

const DittoToastGenerator = (
  type: 'success' | 'error' | 'info' | 'warning',
  message?: string,
  title?: string
) => {
  const icon = {
    success: <SuccessIcon style={{ color: 'white', fontSize: 14 }} />,
    info: <InfoIcon style={{ color: 'white', fontSize: 14 }} />,
    warning: <InfoIcon style={{ color: 'white', fontSize: 14 }} />,
    error: <ErrorIcon style={{ color: 'white', fontSize: 14 }} />
  };

  const iconBg = {
    success: '#5B9E28',
    info: '#3DABF5',
    warning: '#F2AE49',
    error: '#E24C4C'
  };

  if (title || message) {
    const content = (
      <ToastContainer>
        <Box display="flex" flexDirection="row" width="100%">
          <Box
            width={20}
            height={20}
            borderRadius="50%"
            bgcolor={iconBg[type]}
            marginRight={1.4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            marginTop={0.2}
          >
            {icon[type]}
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ fontWeight: 600, marginBottom: 4 }}
            >
              {!title ? startCase(type) : title}
            </Typography>
            {message ? message : null}
          </Box>
        </Box>
      </ToastContainer>
    );

    // calling type based toast -> toast.error(message)
    toast[type](content);
  }
};

export const renderInvalidForm = () => {
  DittoToast.error('Please answer all questions', 'Invalid');
};
