import { Spinner } from './spinner';
import { renderInvalidForm } from './toast';
import { TatDialog } from './tatDialog';

export { renderInvalidForm, Spinner, TatDialog };

export const setLoggedInUser = (user: any) => {
  if (user) {
    localStorage.setItem('ditto_partner', JSON.stringify(user));
  } else {
    localStorage.removeItem('ditto_partner');
  }
};

export const getLoggedInUser = (): any => {
  const loggedIn = localStorage.getItem('ditto_partner');

  let user = null;
  if (loggedIn === null) {
    user = null;
  } else {
    user = JSON.parse(loggedIn);
  }

  return user;
};

export const convertToINR = (
  number: number | string,
  decimals?: number,
  expanded?: boolean
): string | number => {
  let value: number | string = parseInt(number.toString());

  if (value >= 10000000) {
    value = value / 10000000;
    value =
      (decimals
        ? Number(value.toFixed(decimals)).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: decimals,
            style: 'currency',
            currency: 'INR'
          })
        : Math.round(value).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
          })) +
      (!expanded ? '\u00a0Cr' : value <= 10000000 ? '\u00a0Crore' : '\u00a0Crores');
  } else if (value >= 100000) {
    value = value / 100000;
    value =
      (decimals
        ? Number(value.toFixed(decimals)).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: decimals,
            style: 'currency',
            currency: 'INR'
          })
        : Math.round(value).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
          })) +
      (!expanded ? '\u00a0L' : value === 100000 ? '\u00a0Lakh' : '\u00a0Lakhs');
  } else if (value >= 1000) {
    value = value / 1000;
    value =
      (decimals
        ? Number(value.toFixed(decimals)).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: decimals,
            style: 'currency',
            currency: 'INR'
          })
        : Math.round(value).toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
          })) + (!expanded ? '\u00a0K' : '\u00a0Thousand');
  }

  return value;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const getFrequencyUnit = (frequency: string, full?: boolean) => {
  if (frequency === 'Yearly') {
    return !full ? '/yr' : '/year';
  } else if (frequency === 'Monthly') {
    return !full ? '/mo' : '/month';
  }
};

export const getPayOptionString = (frequency: string, payOption: any) => {
  let lastPart = '';
  switch (payOption.key) {
    case 'limited5':
    case 'limited10':
      lastPart = `for next ${payOption.paymentTerm} years`;
      break;
    case 'limited60':
      lastPart = `till you are 60`;
      break;
    case 'regular':
      lastPart = `till end of your policy`;
      break;
  }
  return `${frequency === 'Yearly' ? 'Annually' : 'Monthy'} ${lastPart}`;
};

export const copyToClipboard = async (url: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(url);
  } else {
    return document.execCommand('copy', true, url);
  }
};
