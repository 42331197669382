import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MainLayout } from '../../layout';
import PartnerApi from '../../services/apis/partner';
// import { Robots } from '../../seo';
// import { trackDittoPageView } from '../../services/analytics';
// import SegmentScript from '../../services/analytics/segment/SegementScript';

import theme from '../../theme/theme';
import '../../theme/react-toastify.css';

declare global {
  interface Window {
    analytics: any;
  }
}

function usePageViews() {
  const location = useLocation();
  // to prevent same path being tracked twice due to some internal state changes
  const lastPathname = useRef<string>();
  React.useEffect(() => {
    if (lastPathname.current !== location.pathname) {
      //tracking each page
      // trackDittoPageView(location.pathname);
    }
    lastPathname.current = location.pathname;
  }, [location]);
}

const App: React.FC<any> = () => {
  // usePageViews();
  PartnerApi.createClient();

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const queryString = window.location.search;
  //     const urlParams = new URLSearchParams(queryString);
  //     const referrerCode = urlParams.get(REFERRER_PARAM_NAME);
  //     if (referrerCode) {
  //       document.cookie = `${REFERRER_COOKIE_NAME}=${referrerCode};max-age=${
  //         7 * 24 * 60 * 60
  //       };path=/;${config.COOKIE_DOMAIN ? `domain=${config.COOKIE_DOMAIN};` : ''}`;
  //     }

  //     // inject params to app using url params
  //     storeAppParams(urlParams);
  //   }
  // }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        {/* <SegmentScript /> */}
        {/* <Robots /> */}
        <MainLayout />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
