import { useTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { findLast } from 'lodash';

const drawerWidth = 240;

// use the following only for className prop
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.up('md')]: {
        minHeight: 64
      }
    },
    layout: {
      flex: 1
    },
    authContainerRoot: {
      height: '100vh',
      padding: 0
    },
    containerRoot: {
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 64px)'
      },
      padding: 0,
      overflow: 'scroll',
      position: 'relative'
    },
    switchLabel: {
      fontSize: '14px',
      //color: theme.palette.grey[800],
      lineHeight: '20px',
      '@media(max-width:320px)': {
        fontSize: 10,
        lineHeight: '16px'
      }
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    bottomBarAmount: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 600,
      '@media(max-width:320px)': {
        fontSize: 14
      }
    },
    bottomBarAmountFreq: {
      color: '#3E4142',
      fontSize: 14,
      fontWeight: 'normal',
      '@media(max-width:320px)': {
        fontSize: 12
      }
    },
    tabsFlexContainer: {
      borderBottom: '2px solid #dadada'
    },
    formGroupRoot: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    radioGroupRoot: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    radioRoot: {
      '& .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    radioFormControlLabel: {
      fontSize: '13px',
      color: theme.palette.grey[600]
    },
    radioFormControlLabelSelected: {
      color: theme.palette.text.primary
    },
    xsHorizontalScrollContainer: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
        width: 'calc(100% + 20px)',
        display: 'flex',
        flexWrap: 'nowrap',
        maxWidth: '1140px',
        overflowX: 'auto'
      }
    },
    xsHorizontalScrollItem: {
      [theme.breakpoints.down('xs')]: {
        width: 160,
        marginRight: theme.spacing(1)
      }
    },
    xsHorizontalScrollItemBig: {
      [theme.breakpoints.down('xs')]: {
        width: 200,
        marginRight: theme.spacing(1)
      }
    },
    bullet: {
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.palette.primary.main,
      marginRight: 12,
      marginTop: 7,
      flexShrink: 0
    },
    downloadBtnLabel: {
      fontSize: 14,
      color: '#737575',
      '@media(max-width:320px)': {
        fontSize: 10
      }
    },
    column1: {
      order: 2,
      [theme.breakpoints.up('sm')]: {
        order: 1
      }
    },
    column2: {
      order: 1,
      [theme.breakpoints.up('sm')]: {
        order: 2
      }
    },
    rightSeparator: {
      borderRight: '1px solid #D9DDDE',

      [theme.breakpoints.down('xs')]: {
        borderRight: 'none',
        marginBottom: theme.spacing(2)
      }
    },
    rightSeparatorCenter: {
      borderRight: '1px solid #D9DDDE',
      justifyContent: 'center',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        borderRight: 'none',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2)
      }
    },
    noSeparatorEnd: {
      justifyContent: 'flex-end',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        borderRight: 'none',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2)
      }
    },
    radioDefault: {
      color: '#BFC4C6'
    },
    cardHover: {
      '&:hover': {
        boxShadow: '0px 0px 7px rgba(29, 201, 255, 0.75)'
      }
    },
    authLogo: {
      width: 68,
      height: 32,
      [theme.breakpoints.up('md')]: {
        width: 96,
        height: 45
      }
    },
    partner: {
      marginBottom: 0,
      marginLeft: 2,
      [theme.breakpoints.up('md')]: {
        marginBottom: 4
      }
    }
  })
);

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type ResponsiveValues<P> = {
  [key in Breakpoint]?: P;
};

export const useResponsive = () => {
  const theme: Theme = useTheme();

  const matches = {
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl'))
  };

  return function <P>(responsiveValues: ResponsiveValues<P>) {
    const match: Breakpoint | undefined = findLast(
      theme.breakpoints.keys,
      (breakpoint: Breakpoint) =>
        matches[breakpoint] && responsiveValues[breakpoint] != null
    );

    return match && responsiveValues[match];
  };
};
