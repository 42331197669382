import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Routes from '../routes';
import { useResponsive, useStyles } from '../theme/styles';

const MainLayout: React.FC = () => {
  const classes = useStyles();
  const r = useResponsive();
  return (
    <div className={classes.layout}>
      <CssBaseline />
      <Routes />
      <ToastContainer
        containerId="global-toast-container"
        limit={r({ xs: 2, sm: 3, md: 3 })}
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </div>
  );
};

export { MainLayout };
