import React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useResponsive } from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.grey[50],
    borderRadius: theme.spacing(1.25),
    width: '100%'
  },
  rootHover: {
    '&:hover': {
      boxShadow: '0px 0px 7px rgba(29, 201, 255, 0.75)'
    }
  }
}));

interface PaperProps extends BoxProps {
  clickable?: boolean;
  paperroot?: any;
  paperStyle?: any;
  outerBoxProps?: BoxProps;
  innerBoxProps?: BoxProps;
  paperClasses?: any;
}

const Paper: React.FC<PaperProps> = React.memo((props) => {
  const classes = useStyles();
  const {
    children,
    clickable,
    outerBoxProps,
    paperroot,
    innerBoxProps,
    paperClasses,
    paperStyle,
    ...rest
  } = props;

  const r = useResponsive();

  return React.Children.toArray(children).filter(Boolean).length ? (
    <Box
      style={
        clickable
          ? {
              cursor: 'pointer'
            }
          : undefined
      }
      width="100%"
      {...outerBoxProps}
      {...rest}
    >
      <MuiPaper
        classes={
          !paperClasses
            ? {
                root: clsx(
                  classes.root,
                  paperroot ? paperroot : null,
                  clickable ? classes.rootHover : null
                )
              }
            : paperClasses
        }
        elevation={0}
        style={paperStyle ? paperStyle : undefined}
      >
        <Box
          width="100%"
          paddingTop={r({ xs: 1.4, sm: 1.4, md: 2.4 })}
          paddingBottom={r({ xs: 1.4, sm: 1.4, md: 2.4 })}
          paddingLeft={r({ xs: 1.4, sm: 1.4, md: 2.4 })}
          paddingRight={r({ xs: 1.4, sm: 1.4, md: 2.4 })}
          {...innerBoxProps}
        >
          {children}
        </Box>
      </MuiPaper>
    </Box>
  ) : null;
});

export { Paper };
