import config from '../../../config';
import {
  ConfirmPasswordPayload,
  DashboardStatsPayload,
  FetchConvertsPayload,
  FetchLeadsPayload,
  ResetLinkRequestPayload
} from '../../../types';
import ApiClient from '../client';

const { PARTNER_API } = config;

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/*
API Client for Partner API interaction
*/
let client: any = null;

export default {
  createClient() {
    if (!client && PARTNER_API) {
      client = new ApiClient(PARTNER_API).client;
    }
  },
  async signIn(email: string, password: string) {
    const response = await client.post(`/partner/login`, {
      email,
      password
    });

    return response.data ? response.data : null;
  },
  async fetchConverts(payload: FetchConvertsPayload) {
    const response = await client.get(`/partner/convert/list`, { params: payload });
    return response.data && response.data.data ? response.data.data : null;
  },
  async fetchLeads(payload: FetchLeadsPayload) {
    const response = await client.get(`/partner/lead/list`, { params: payload });
    return response.data && response.data.data ? response.data.data : null;
  },
  async requestPasswordResetLink(payload: ResetLinkRequestPayload) {
    const response = await client.post(`/partner/password_reset/request`, payload);
    return response.data?.data;
  },
  async confirmNewPassword(payload: ConfirmPasswordPayload) {
    const response = await client.post(`/partner/password_reset/confirm`, payload);
    return response.data?.data;
  },
  async fetchDashboardStats(payload: DashboardStatsPayload) {
    const response = await client.get(`/partner/dashboard`, { params: payload });
    return response.data?.data;
  }
};
