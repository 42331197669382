import { Box, Popover, Typography, Hidden } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import moment from 'moment';
import { FC, useState } from 'react';
import {
  DateRangePicker as CustomDateRangePicker,
  Range,
  DateRange as CustomDateRange
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button } from '../..';
import { useResponsive } from '../../../theme/styles';

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

interface BasicDateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  onChange: (dateRange: DateRange) => void;
}

const BasicDateRangePicker: FC<BasicDateRangePickerProps> = ({
  startDate,
  endDate,
  onChange
}) => {
  const r = useResponsive();
  const [state, setState] = useState<[Range]>([
    {
      startDate: startDate
        ? startDate
        : moment(new Date()).subtract(30, 'days').toDate(),
      endDate: endDate ? endDate : new Date(),
      key: 'selection'
    }
  ]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        onClick={handleClick}
        bgcolor="white"
        border="1px solid #dadada"
        paddingX={1.6}
        paddingY={1}
        borderRadius={8}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2">
          <Box component="span" fontWeight="normal">
            {`${moment(state[0].startDate).format('D MMM YYYY')} - ${moment(
              state[0].endDate
            ).format('D MMM YYYY')}`}
          </Box>
        </Typography>
        <CalendarToday style={{ color: '#616262', width: 20, height: 20 }} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box display="flex" flexDirection="column">
          <Hidden mdUp>
            <CustomDateRange
              onChange={(item) => {
                setState([item.selection]);
                onChange({
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate
                });
              }}
              moveRangeOnFirstSelection={false}
              months={r({ md: 2, xs: 1 })}
              ranges={state}
              direction={r({ xs: 'vertical', md: 'horizontal' })}
            />
          </Hidden>
          <Hidden smDown>
            <CustomDateRangePicker
              onChange={(item) => {
                setState([item.selection]);
                onChange({
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate
                });
              }}
              moveRangeOnFirstSelection={false}
              months={r({ md: 2, xs: 1 })}
              ranges={state}
              direction={r({ xs: 'vertical', md: 'horizontal' })}
              inputRanges={[]}
            />
          </Hidden>
          {/* <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            padding={1}
          >
            <Button
              onClick={() => {
                setState([
                  {
                    startDate: undefined,
                    endDate: undefined,
                    key: 'selection'
                  }
                ]);
                onChange({
                  startDate: undefined,
                  endDate: undefined
                });
                handleClose();
              }}
              color="primary"
              variant="outlined"
              size="small"
              disableElevation
            >
              Till now
            </Button>
          </Box> */}
        </Box>
      </Popover>
    </>
  );
};

export { BasicDateRangePicker };
