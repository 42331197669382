import React from 'react';

const ValidationIcon = () => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANoSURBVHgB7ZrBddpAEIZHwgdiLi5BPtgRfjlAKsBpIEkFhgrsVIBTQZwKQiqIOwgdmIMfKKcoFYRL4gMPbWZleI+HJTHSzqwce7/3sDHaxTv/jnZ+rQTgcDgcDofjueJBTdwEwUGz2eys/ozbURRDDVgX4PbkpLOn1Cd829s6NFZKDWwLYVWA6fHxO7/R+FbUxlOq/zKKvoIlrAkwC8MAfP8GAzyA4gHNE6W6tjLBB1t43uWu4DUK4MDz/SFYwkoG6Nn3PO9nmT64HpxiFoxBGCsZgMF/gZJgHytZIC4Azn4fHq74FHrYtwfCiAtgMpNVMqcsogKsZj+A6gRRu30BgogtgquF7zuYCZCWxb93d4fdOJ6DAGIZgF98BobBa3RZbO7vi2WBSAZUKXtFSJojmQxA00Nqp9QYf17vbCZojtgzoMTsfwhns6tVnz5lxZcwR+wZQC1d6+A1GNSI0kfCHLEKYGB6qLCbI1YBbNhXbnPEJgCD6aHCao5YBFgtfDYvYYd6Sw0YYBGAy/RQ4TRHxgLo2VfUus+Ir9R5ustk+j1gSg3Ba7jMkZEA6bl/n/71gBuopmXRSAAb1+uEMRhlQWUBLJgeKkbmqLIANsveLkwysZIAFk0PlcrmqLQAtk0PlarmqLQAXKZnc7Ac9byqOSolAKfpedFqpVmkhWh43jkwUMUcldoQmbXbI+66j4LOKbfMyHjeKJxOB+Tm1Ibc+3ySlNk5Ip8Cj8H0UCmzSJMEeESmhwrZHJFOAayxOvUDYCQ995PkGqdLn/8d4PcVMb4Oce+xsNHODBAyPTEkSTeMogEO8L0+Z+F+wJwE+LrAKlPYqFAAKdODq/7l5k0O/T4B+AzMaHOEvworjL/joMhODwb7a/szFGUCzKzNEU5kbptcAUR3eny/t/0RmqG3IIA2R1AwifkZILjTg7M93Lx4+RGGZ5gVIjdA1ztHeWtBbhXAASoQRleCdBCcTjCDolvse1kdbo+OOmAB6cDXpGtBs6n/1wMB7D0mVzON5TJT7EwBFq1WvE7Pp8KfxSLO+jxTgO5kMpcoS7Wh1CjvERs/v48aPIUswBh+4xrwMe94rgDanS0BTv9nEXTwGMObokdrChfBV9PpRHt2VFA/vR1DvSjqSweOvuIKx/46jcHhcDgcDkcW/wCJSkIzRXRC7gAAAABJRU5ErkJggg=="
      style={{
        height: 14,
        width: 14,
        marginTop: 6,
        marginRight: 4,
        marginLeft: 4
      }}
      alt="invalid"
    />
  );
};

export { ValidationIcon };
