import React from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  selectInputRoot: {
    background: 'white',
    width: '100%',
    '&:focus': {
      backgroundColor: 'white'
    },
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    '&.MuiSelect-select': {
      paddingRight: '12px'
    }
  },
  select: {
    paddingRight: '12px'
  },
  inputRoot: {
    padding: 0
  }
}));

interface DropDownSwitch {
  selected?: string;
  onChange: (selected: string) => void;
  labels: [string, string];
}

const DropDownSwitch: React.FC<DropDownSwitch> = ({
  selected,
  labels,
  onChange
}: DropDownSwitch) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    event.stopPropagation();
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <Select
        value={selected}
        onChange={handleChange}
        classes={{ root: classes.selectInputRoot, select: classes.select }}
        inputProps={{ classes: { root: classes.inputRoot } }}
        disableUnderline
      >
        {labels.map((label, index) => {
          return (
            <MenuItem key={index} value={label}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export { DropDownSwitch };
