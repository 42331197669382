import axios from 'axios';
import { startCase, upperFirst, toLower } from 'lodash';
import * as Sentry from '@sentry/react';

import envConfig, {
  APP_STAGE_DEVELOPMENT,
  APP_STAGE_STAGING
} from '../../../config';
import { DittoToast } from '../../../utils/toast';

const userFriendlyMessage = (message: string, title?: string): void => {
  const finalMEssage = upperFirst(toLower(message || ''));
  DittoToast.error(finalMEssage, title);
};

const getClient = (baseUrl: string, headers?: Record<string, unknown>) => {
  const config: any = {
    baseURL: baseUrl,
    contentType: 'application/json'
  };

  if (headers) {
    config.headers = headers;
  }

  config.withCredentials = true;
  config.xsrfCookieName = 'csrftoken';
  config.xsrfHeaderName = 'X-CSRFToken';
  config.timeout = 310 * 1000; // 310 sec timeout

  const client = axios.create(config);

  client.interceptors.request.use(
    (requestConfig) => requestConfig,
    (requestError) => {
      return Promise.reject(requestError);
    }
  );

  client.interceptors.request.use((config: any) => {
    // request custom config goes here
    //console.log({ config });
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (
        envConfig.APP_STAGE === APP_STAGE_DEVELOPMENT ||
        envConfig.APP_STAGE === APP_STAGE_STAGING
      ) {
        //don't remove
        console.log({ response });
      }
      return Promise.resolve(response);
    },
    (error) => {
      /*  Potential errors : 401, 403, 500
          401 vs 403: https://stackoverflow.com/questions/3297048/403-forbidden-vs-401-unauthorized-http-responses
        */

      let message = '';

      if (error.response && error.response !== null) {
        if (error.response.status >= 500) {
          message = 'Looks like an issue at server! Our team is fixing it.';
          userFriendlyMessage(message, 'Server error');
        } else if (error.response.status === 403) {
          // authentication error
          let message = error.response.data
            ? error.response.data['message'] || error.response.data['detail']
            : undefined;
          message = message ? message : 'Please login and retry!.';

          // // Invoking the global modal.
          // GlobalRegistrationDialog.toggle({
          //   isShown: true,
          //   onAuthSuccess: () => {
          //     // Delay just to make sure user check the toast
          //     setTimeout(() => {
          //       window.location.reload();
          //     }, 1000);
          //   }
          // });
        } else if (error.response.status === 401) {
          //Unauthenticated request. Check if the header is present & if yes, the token is invalidated. User needs to login again
          //console.error('ERROR: ', error.response.data.detail);
          message = 'Please login and try again';
          userFriendlyMessage('Please login and try again', 'Error');
        } else if (error.response.status === 400) {
          //console.error('ERROR: ', error.response.data);
          const data = error.response.data;
          const errorKeys = data.errors ? Object.keys(data.errors) : [];

          if (errorKeys.length > 0) {
            errorKeys.forEach((key) => {
              const isOtherErrors = key === 'other_errors';

              if (isOtherErrors) {
                const otherErrors = data.errors[key] || [];
                otherErrors.forEach((error: any) => {
                  userFriendlyMessage(error, 'Error');
                });
              } else {
                message = `${startCase(key)} - ${data.errors[key]}`;
                userFriendlyMessage(message, 'Error');
              }
            });
          } else if (error.response.data.message) {
            message =
              typeof error.response.data.message === 'object'
                ? Object.keys(error.response.data.message).reduce(
                    (prev, key) =>
                      prev +
                      error.response.data.message[key].map((err: string) => err),
                    ''
                  )
                : error.response.data.message;
            userFriendlyMessage(message, 'Error');
          } else {
            userFriendlyMessage('Not able to process your request.', 'Error');
          }

          // TODO - Can remove this - just keeping as backup
          /*
          if (data && data.message.length > 0) {
            userFriendlyMessage(data.message);
          } else {
            if (Object.keys(data.message || {}).length > 0) {
              userFriendlyMessage(data.message['error'], 'Error');
            } else {
              userFriendlyMessage('Not able to process your request.', 'Error');
            }
          }
          */
        } else if (error.response.status === 404) {
          // remove or change the meassage in prod
          userFriendlyMessage('404 : No such end point', 'Error');
        }
      } else {
        // remove once 499 issue is resolved with care
        console.log(error.message);
        console.log(error.code);
        Sentry.captureException(error);
        message =
          'Not able to connect our servers due to a network error. Please try again!';
        // network error
        userFriendlyMessage(message, 'Error');
      }
      // send error forward for any further handling
      return Promise.reject(new Error(message));
    }
  );
  return client;
};

export class APIClient {
  client: any;

  constructor(baseUrl: string, headers?: Record<string, unknown>) {
    this.client = getClient(baseUrl, headers);
  }
}

export default APIClient;
