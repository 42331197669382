import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';
import MuiSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from '../../theme/styles';
import clsx from 'clsx';

const InsuranceSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 22,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 4,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px) !important',
      color: theme.palette.primary.main,
      '& + $track': {
        opacity: 0.15,
        backgroundColor: '#3DABF5',
        borderColor: 'rgba(0, 0, 0, 0.8)'
      }
    }
  },
  thumb: {
    width: 14,
    height: 14,
    borderRadius: 7,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 12,
    opacity: 1,
    background: theme.palette.grey[100],
    boxShadow: 'none'
  },
  checked: {}
}))(MuiSwitch);

const InsuranceSwitchSmall = withStyles((theme) => ({
  root: {
    width: 24,
    height: 12,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 1,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(11px) !important',
      color: theme.palette.primary.main,
      '& + $track': {
        opacity: 0.15,
        backgroundColor: '#3DABF5',
        borderColor: 'rgba(0, 0, 0, 0.8)'
      }
    }
  },
  thumb: {
    width: 10,
    height: 10,
    borderRadius: 5,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    background: theme.palette.grey[100]
  }
}))(MuiSwitch);

const InsuranceSwitchColored = withStyles((theme) => ({
  root: {
    width: 42,
    height: 22,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 4,
    color: theme.palette.primary.main,
    '&$checked': {
      transform: 'translateX(20px) !important',
      color: theme.palette.primary.main,
      '& + $track': {
        opacity: 0.15,
        backgroundColor: '#3DABF5',
        borderColor: 'rgba(0, 0, 0, 0.8)'
      }
    }
  },
  thumb: {
    width: 14,
    height: 14,
    borderRadius: 7,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 12,
    opacity: 1,
    background: theme.palette.grey[100],
    boxShadow: 'none'
  },
  checked: {}
}))(MuiSwitch);

const InsuranceSwitchSmallColored = withStyles((theme) => ({
  root: {
    width: 24,
    height: 12,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 1,
    color: theme.palette.primary.main,
    '&$checked': {
      transform: 'translateX(11px) !important',
      color: theme.palette.primary.main,
      '& + $track': {
        opacity: 0.15,
        backgroundColor: '#3DABF5',
        borderColor: 'rgba(0, 0, 0, 0.8)'
      }
    }
  },
  thumb: {
    width: 10,
    height: 10,
    borderRadius: 5,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    background: theme.palette.grey[100]
  }
}))(MuiSwitch);

interface Option {
  label: string;
  value: string | number | boolean;
}

interface SwitchProps {
  name?: string | number;
  checked: boolean | string | undefined;
  onChange: (checked: boolean | string | number) => void;
  options: [Option, Option];
  returnType?: 'boolean' | 'value';
  small?: boolean;
  disabled?: boolean;
  /**
   * the default behaviour is to use switch component for smUp and Radio for xsDown
   * in order to use switch in mobile as well as desktop use this prop
   *  */
  useSwitchAlways?: boolean;
  highlightSelected?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  name,
  checked,
  options,
  small,
  returnType,
  disabled,
  useSwitchAlways,
  highlightSelected,
  onChange
}: SwitchProps) => {
  const classes = useStyles();
  const handleChange = (
    component: 'switch' | 'radio',
    event: any,
    value?: any
  ) => {
    const finalValue =
      component === 'switch'
        ? event.target.checked
        : event.target.value === 'true'
        ? true
        : false;

    if (!returnType || returnType === 'value') {
      onChange(finalValue ? options[1].value : options[0].value);
    } else {
      onChange(finalValue);
    }
  };

  const validateAndSetChecked = () => {
    if (disabled) {
      return false;
    } else if (typeof checked === 'string' || typeof checked === 'number') {
      return checked === options[0].value ? false : true;
    }
    return checked !== undefined ? checked : false;
  };

  const validateAndSetCheckedRadio = () => {
    if (disabled) {
      return false;
    } else if (typeof checked === 'string' || typeof checked === 'number') {
      return checked === options[0].value ? false : true;
    }
    return checked === undefined ? '' : checked;
  };

  const renderSwitch = () => {
    return !small ? (
      !highlightSelected ? (
        <InsuranceSwitch
          checked={validateAndSetChecked()}
          onChange={(event, checked) => handleChange('switch', event, checked)}
          name="insuranceswitch"
          disabled={disabled}
        />
      ) : (
        <InsuranceSwitchColored
          checked={validateAndSetChecked()}
          onChange={(event, checked) => handleChange('switch', event, checked)}
          name="insuranceswitch"
          disabled={disabled}
        />
      )
    ) : !highlightSelected ? (
      <InsuranceSwitchSmall
        checked={validateAndSetChecked()}
        onChange={(event, checked) => handleChange('switch', event, checked)}
        name="insuranceswitchsmall"
        disabled={disabled}
      />
    ) : (
      <InsuranceSwitchSmallColored
        checked={validateAndSetChecked()}
        onChange={(event, checked) => handleChange('switch', event, checked)}
        name="insuranceswitchsmall"
        disabled={disabled}
      />
    );
  };

  const renderLabel = () => {
    return (
      <Box
        bgcolor="#E8EAEB"
        paddingX={0.8}
        paddingY={0.4}
        borderRadius={4}
        width={35}
        textAlign="center"
      >
        <Typography
          style={{
            fontSize: '10px',
            lineHeight: '12px',
            color: '#525454',
            fontWeight: 500
          }}
        >
          {checked ? 'YES' : 'NO'}
        </Typography>
      </Box>
    );
  };

  let finalComponent = null;

  if (disabled && checked !== undefined) {
    finalComponent = renderLabel();
  } else {
    const switchComponent = (
      <Typography component="div">
        <Grid component="label" container alignItems="center">
          <Grid item>
            <Box marginRight={0.8}>
              <Typography
                color={
                  checked === options[0].value && highlightSelected
                    ? 'textPrimary'
                    : 'textSecondary'
                }
                className={classes.switchLabel}
              >
                {options[0].label}
              </Typography>
            </Box>
          </Grid>
          <Grid item>{renderSwitch()}</Grid>
          <Grid item>
            <Box marginLeft={0.8}>
              <Typography
                color={
                  checked === options[1].value && highlightSelected
                    ? 'textPrimary'
                    : 'textSecondary'
                }
                className={classes.switchLabel}
              >
                {options[1].label}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Typography>
    );

    if (useSwitchAlways) {
      finalComponent = <FormGroup>{switchComponent}</FormGroup>;
    } else {
      finalComponent = (
        <FormGroup classes={{ root: classes.formGroupRoot }}>
          <Hidden xsDown>{switchComponent}</Hidden>
          <Hidden smUp>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={validateAndSetCheckedRadio()}
              onChange={(event, value) => handleChange('radio', event, value)}
              classes={{
                root: classes.radioGroupRoot
              }}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    color="primary"
                    classes={{ root: classes.radioRoot }}
                  />
                }
                label="Yes"
                classes={{
                  label: clsx(
                    classes.radioFormControlLabel,
                    validateAndSetCheckedRadio() === true
                      ? classes.radioFormControlLabelSelected
                      : null
                  )
                }}
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    color="primary"
                    classes={{ root: classes.radioRoot }}
                  />
                }
                label="No"
                classes={{
                  label: clsx(
                    classes.radioFormControlLabel,
                    validateAndSetCheckedRadio() === false
                      ? classes.radioFormControlLabelSelected
                      : null
                  )
                }}
              />
            </RadioGroup>
          </Hidden>
        </FormGroup>
      );
    }
  }

  return finalComponent;
};

export { Switch };
