import React, { isValidElement } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Paper } from '../surfaces';
import { useResponsive } from '../../theme/styles';
import { drawerWidth, drawerWidthMobile } from '../../theme/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        width: drawerWidthMobile,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
      }
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#FFD67D',
      borderLeft: 'none',
      [theme.breakpoints.down('sm')]: {
        width: drawerWidthMobile
      }
    }
  })
);

interface DittoHotlineDrawerProps {
  isOpen: boolean;
  handleDrawerClose: () => void;
}

export const DittoHotlineDrawer: React.FC<DittoHotlineDrawerProps> = ({
  isOpen,
  handleDrawerClose
}) => {
  const r = useResponsive();
  const classes = useStyles();
  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        handleDrawerClose();
      }}
      className={classes.drawer}
      variant={r({
        lg: 'permanent',
        md: 'temporary',
        sm: 'temporary',
        xs: 'temporary'
      })}
      classes={{
        paper: classes.drawerPaper
      }}
      anchor={r({ lg: 'right', md: 'right', sm: 'bottom', xs: 'bottom' })}
    >
      <Box
        height={r({ md: '100vh', xs: 'auto' })}
        width="100%"
        padding={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography variant="h4" color="textPrimary" paragraph>
          We are a click away
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          If something is not clear or you are unable to decide on what’s best for
          you, we can assist you. Choose one of the ways to contact us
        </Typography>
        <Paper width="100%">
          <Box padding={2} flexDirection="row" display="flex" alignItems="center">
            <Skeleton width={48} height={48} variant="rect" />
            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography variant="h6">Chat with us</Typography>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" color="textSecondary">
                  9am to 9am
                </Typography>
                <ArrowForward htmlColor="#444" />
              </Box>
            </Box>
          </Box>
        </Paper>
        <Paper width="100%" marginTop={2}>
          <Box padding={2} flexDirection="row" display="flex" alignItems="center">
            <Skeleton width={48} height={48} variant="rect" />
            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography variant="h6">Request a call</Typography>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" color="textSecondary">
                  9am to 9am
                </Typography>
                <ArrowForward htmlColor="#444" />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Drawer>
  );
};
