import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PageLoader } from '../components';
import ErrorBoundary from '../utils/ErrorBoundary';

import {
  ROUTE_DASHBOARD,
  ROUTE_PROFILE,
  ROUTE_REFERRAL_LINKS,
  ROUTE_LEADS
} from './constants';
import { NoAuth, RequireAuth } from './Protected';
import { AuthProvider } from '../context/AuthProvider';

const Partner = lazy(() => import('../views/Partner'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const ReferralLinks = lazy(() => import('../views/ReferralLinks'));
const Leads = lazy(() => import('../views/Leads'));
const Profile = lazy(() => import('../views/Profile'));
const Login = lazy(() => import('../views/Login'));
const ResetPassword = lazy(() => import('../views/ResetPassword'));
const UpdatePassword = lazy(() => import('../views/UpdatePassword'));

const NoMatch = () => {
  return <div>404 not found</div>;
};

const AppRoutes = () => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <NoAuth>
                  <Login />
                </NoAuth>
              </React.Suspense>
            }
          />
          <Route
            path="/reset"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <NoAuth>
                  <ResetPassword />
                </NoAuth>
              </React.Suspense>
            }
          />
          <Route
            path="/password"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <UpdatePassword />
              </React.Suspense>
            }
          />
          <Route
            path="/*"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <RequireAuth>
                  <Partner />
                </RequireAuth>
              </React.Suspense>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTE_DASHBOARD}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTE_REFERRAL_LINKS}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ReferralLinks />
                </React.Suspense>
              }
            />

            <Route
              path={ROUTE_LEADS}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Leads />
                </React.Suspense>
              }
            />

            <Route
              path={ROUTE_PROFILE}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Profile />
                </React.Suspense>
              }
            />

            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default AppRoutes;
