import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createSpacing from '@material-ui/core/styles/createSpacing';
// https://material-ui.com/components/about-the-lab/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as type from '@material-ui/lab/themeAugmentation';

const breakpoints = createBreakpoints({});
const spacing = createSpacing(10);

const theme = responsiveFontSizes(
  createTheme({
    spacing: 10,
    palette: {
      primary: {
        main: '#3DABF5',
        contrastText: '#fff'
      },
      secondary: {
        main: '#B0D9F4',
        contrastText: '#fff'
      },
      text: {
        primary: '#1E1F20',
        secondary: '#525454'
      },
      success: {
        main: '#00C48C'
      },
      background: {
        default: 'white'
      },
      error: {
        light: '#FEECEC',
        main: '#D82929',
        dark: '#D82929'
      },
      grey: {
        50: '#F7F9FA',
        100: '#F2F4F5',
        200: '#E8EAEB',
        300: '#D9DDDE',
        400: '#BFC4C6',
        500: '#9CA3A5',
        600: '#737575',
        700: '#525454',
        800: '#3E4142',
        900: '#1E1F20'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1110,
        xl: 1920
      }
    },
    typography: {
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      h3: {
        fontWeight: 600,
        fontSize: '34px',
        lineHeight: '1.5',
        '@media (max-width: 960px)': {
          fontSize: '24px'
        }
      },
      h4: {
        fontWeight: 600,
        fontSize: '28px !important',
        '@media (min-width: 960px)': {
          fontSize: '28px !important'
        },
        '@media (max-width: 960px)': {
          fontSize: '20px'
        }
      },
      h6: {
        fontWeight: 'bold'
      },
      subtitle1: {
        fontSize: '1.125rem',
        lineHeight: '1.5'
      },
      body1: {
        '@media (max-width: 320px)': {
          fontSize: '14px'
        }
      },
      body2: {
        '@media (max-width: 960px)': {
          fontSize: '13px'
        },
        '@media (max-width: 320px)': {
          fontSize: '12px'
        }
      }
    },
    overrides: {
      MuiGrid: {
        'spacing-xs-1': {
          marginTop: 0,
          marginBottom: 0,
          '& .MuiGrid-grid-xs-12,.MuiGrid-grid-xs-6': {
            paddingTop: '0px',
            paddingBottom: '0px'
          }
        }
      },
      MuiContainer: {
        root: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      MuiPaper: {
        root: {
          boxShadow: 'none'
        },
        elevation1: {
          boxShadow: 'none'
        },
        rounded: {
          borderRadius: 12
        }
      },
      MuiButton: {
        root: {
          borderRadius: 8,
          height: 40
        },
        disableElevation: {
          '&:hover': {
            background: '#38BFEA'
          }
        },
        sizeLarge: {
          paddingTop: '8.5px',
          paddingBottom: '8.5px'
        },
        sizeSmall: {
          height: 32,
          paddingLeft: '25px',
          paddingRight: '25px',
          '@media(max-width:960px)': {
            height: 32,
            paddingLeft: '12px',
            paddingRight: '12px',
            '& .MuiButton-label': {
              fontSize: 12
            }
          },
          '@media(max-width:320px)': {
            height: 28,
            paddingLeft: '10px',
            paddingRight: '10px',
            '& .MuiButton-label': {
              fontSize: 10
            }
          }
        },
        label: {
          fontSize: '16px',
          lineHeight: '20px',
          fontWeight: 500,
          textTransform: 'none',
          '@media(max-width: 320px)': {
            fontSize: 14
          }
        },
        outlined: {
          background: 'white'
        },
        outlinedPrimary: {
          border: '2px solid #3DABF5',
          '&:hover': {
            color: 'white',
            border: '2px solid #38BFEA'
          }
        },
        startIcon: {
          marginRight: 6
        },
        endIcon: {
          '& *:first-child': {
            fontSize: '18px !important'
          }
        }
      },
      MuiInputLabel: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.2',
          //color: '#1E1F20',
          color: '#525454',
          marginBottom: spacing(1)
        }
      },
      MuiFormControl: {
        root: {
          borderRadius: 8
        }
      },
      MuiFormHelperText: {
        root: {
          color: '#D82929',
          marginTop: 6
        },
        contained: {
          marginLeft: 22,
          '&::before': {
            backgroundImage:
              'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANoSURBVHgB7ZrBddpAEIZHwgdiLi5BPtgRfjlAKsBpIEkFhgrsVIBTQZwKQiqIOwgdmIMfKKcoFYRL4gMPbWZleI+HJTHSzqwce7/3sDHaxTv/jnZ+rQTgcDgcDofjueJBTdwEwUGz2eys/ozbURRDDVgX4PbkpLOn1Cd829s6NFZKDWwLYVWA6fHxO7/R+FbUxlOq/zKKvoIlrAkwC8MAfP8GAzyA4gHNE6W6tjLBB1t43uWu4DUK4MDz/SFYwkoG6Nn3PO9nmT64HpxiFoxBGCsZgMF/gZJgHytZIC4Azn4fHq74FHrYtwfCiAtgMpNVMqcsogKsZj+A6gRRu30BgogtgquF7zuYCZCWxb93d4fdOJ6DAGIZgF98BobBa3RZbO7vi2WBSAZUKXtFSJojmQxA00Nqp9QYf17vbCZojtgzoMTsfwhns6tVnz5lxZcwR+wZQC1d6+A1GNSI0kfCHLEKYGB6qLCbI1YBbNhXbnPEJgCD6aHCao5YBFgtfDYvYYd6Sw0YYBGAy/RQ4TRHxgLo2VfUus+Ir9R5ustk+j1gSg3Ba7jMkZEA6bl/n/71gBuopmXRSAAb1+uEMRhlQWUBLJgeKkbmqLIANsveLkwysZIAFk0PlcrmqLQAtk0PlarmqLQAXKZnc7Ac9byqOSolAKfpedFqpVmkhWh43jkwUMUcldoQmbXbI+66j4LOKbfMyHjeKJxOB+Tm1Ibc+3ySlNk5Ip8Cj8H0UCmzSJMEeESmhwrZHJFOAayxOvUDYCQ995PkGqdLn/8d4PcVMb4Oce+xsNHODBAyPTEkSTeMogEO8L0+Z+F+wJwE+LrAKlPYqFAAKdODq/7l5k0O/T4B+AzMaHOEvworjL/joMhODwb7a/szFGUCzKzNEU5kbptcAUR3eny/t/0RmqG3IIA2R1AwifkZILjTg7M93Lx4+RGGZ5gVIjdA1ztHeWtBbhXAASoQRleCdBCcTjCDolvse1kdbo+OOmAB6cDXpGtBs6n/1wMB7D0mVzON5TJT7EwBFq1WvE7Pp8KfxSLO+jxTgO5kMpcoS7Wh1CjvERs/v48aPIUswBh+4xrwMe94rgDanS0BTv9nEXTwGMObokdrChfBV9PpRHt2VFA/vR1DvSjqSweOvuIKx/46jcHhcDgcDkcW/wCJSkIzRXRC7gAAAABJRU5ErkJggg==)',
            paddingRight: 6,
            backgroundSize: 'auto 16px',
            display: 'inline-block',
            width: 14,
            height: 16,
            position: 'absolute',
            content: `''`,
            backgroundRepeat: 'no-repeat',
            top: 'auto',
            left: 2
          }
        }
      },
      MuiSlider: {
        mark: {
          backgroundColor: 'transparent'
        }
      },
      MuiInputBase: {
        root: {
          height: 44
        },
        input: {
          height: '100%',
          boxSizing: 'border-box'
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '0.5rem',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9CA3A5'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #3DABF5',
            boxShadow: '0px 0px 0px 2px #E6F9FF'
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D82929 !important',
            boxShadow: 'none !important'
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E8EAEB !important'
          }
        },
        input: {
          color: '#1E1F20',
          '&::placeholder': {
            color: '#9CA3A5'
          },
          '&:hover::placeholder': {
            color: '#737575'
          },
          '&:focus': {
            color: '#3E4142 !important'
          },
          [breakpoints.down('sm')]: {
            fontSize: '14px',
            paddingTop: spacing(1),
            paddingBottom: spacing(1)
          },
          [breakpoints.up('md')]: {
            fontSize: '1rem', //0.875rem
            lineHeight: '1.25rem',
            padding: spacing(1.6)
          }
        },
        notchedOutline: {
          borderColor: '#BFC4C6'
        }
      },
      MuiSelect: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        selectMenu: {
          height: '1.25rem',
          minHeight: 44
        },
        iconOutlined: {
          paddingRight: 7,
          width: 'auto',
          right: 0,
          background: 'white'
        },
        iconOpen: {
          paddingRight: 0,
          paddingLeft: 7
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: 0,
            [breakpoints.down('sm')]: {
              paddingTop: '10px',
              paddingBottom: '10px'
            }
          }
        }
      },
      MuiListItem: {
        gutters: {
          paddingLeft: '24px',
          paddingRight: '24px'
        }
      },
      MuiMenuItem: {
        root: {
          fontSize: '14px',
          color: 'black'
        }
      },
      MuiDialog: {
        scrollBody: {
          '@media(max-width:960px)': {
            '&:after': {
              verticalAlign: 'bottom'
            }
          }
        }
      },
      MuiAlert: {
        standardError: {
          color: '#D82929'
        }
      }
    }
  })
);

export default theme;
