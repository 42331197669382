import React, { useState } from 'react';

import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputLabel from '@material-ui/core/InputLabel';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import { Moment } from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { useField } from 'formik';

const useStyles = makeStyles((theme: Theme) => ({
  datePickerRootContainer: {
    marginTop: theme.spacing(2)
  },
  datePickerLabel: {
    marginBottom: theme.spacing(1)
  },
  datePickerContainer: {
    width: '100%'
  },
  datePickerRoot: {
    background: 'white',
    paddingRight: theme.spacing(0),
    marginTop: theme.spacing(0)
  },
  // datePickerInputRoot: {
  //   fontSize: "18px",
  //   paddingTop: "13.5px",
  //   paddingBottom: "13.5px",
  // },
  datePickerIcon: {
    color: '#999999'
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#FDFDFD'
  }
}));

interface KeyboardDatePickerProps {
  name: string;
  label: string;
  error?: string;
  placeHolder?: string;
  maxDate?: Moment;
  minDate?: Moment;
  disabled?: boolean;
}

const KeyboardDatePicker: React.FC<KeyboardDatePickerProps> = React.memo(
  (props: KeyboardDatePickerProps) => {
    const {
      name,
      label,
      placeHolder,
      maxDate,
      minDate,
      disabled,
      error
    } = props;
    const [focussed, setFocussed] = useState<boolean>(false);
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);

    return (
      <div className={classes.datePickerRootContainer}>
        <InputLabel
          focused={focussed}
          classes={{ root: classes.datePickerLabel }}
          error={(meta.error && meta.touched) || error ? true : false}
        >
          {label}
        </InputLabel>
        <MuiKeyboardDatePicker
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          margin="none"
          id={`date-picker-dialog-${name}`}
          format="DD/MM/YYYY"
          value={field.value}
          onChange={(date: MaterialUiPickersDate) => {
            helpers.setValue(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'Change date'
          }}
          onFocus={() => {
            setFocussed(true);
          }}
          onBlur={(e) => {
            field.onBlur(e);
            setFocussed(false);
            helpers.setTouched(true);
          }}
          inputVariant="outlined"
          className={classes.datePickerContainer}
          InputProps={{
            classes: {
              root: classes.datePickerRoot,
              disabled: classes.textInputDisabled
            },
            placeholder: placeHolder
          }}
          helperText={
            meta.error && meta.touched ? meta.error : error ? error : ''
          }
          keyboardIcon={
            <CalendarTodayOutlined classes={{ root: classes.datePickerIcon }} />
          }
          error={(meta.error && meta.touched) || error ? true : false}
        />
      </div>
    );
  }
);

export { KeyboardDatePicker };
