import React, { useState, useRef } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputLabel from '@material-ui/core/InputLabel';
import moment, { Moment } from 'moment';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from 'formik';
import clsx from 'clsx';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  datePickerRootContainer: {
    marginTop: 30,
    position: 'relative'
  },
  datePickerLabel: {
    marginBottom: theme.spacing(1)
  },
  datePickerContainer: {
    width: '100%',
    color: '#1E1F20'
  },
  datePickerRoot: {
    background: 'white',
    paddingRight: theme.spacing(0),
    marginTop: theme.spacing(0),
    height: 44
  },
  datePickerIcon: {
    color: '#999999'
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important'
  },
  textInputFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  ageLabel: {
    right: '12px',
    width: '80px',
    height: '20px',
    position: 'absolute',
    backgroundColor: '#E8EAEB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '10px',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary
  },
  ageLabelBase: {
    bottom: '12px'
  },
  ageLabelError: {
    bottom: '38px'
  },
  inputAdornment: {
    display: 'none'
  }
}));

interface DatePickerProps {
  name: string;
  label: string;
  error?: string;
  placeHolder?: string;
  dateFormat?: string;
  maxDate?: Moment;
  minDate?: Moment;
  disabled?: boolean;
  marginTop?: number;
}

const DatePicker: React.FC<DatePickerProps> = React.memo(
  (props: DatePickerProps) => {
    const {
      name,
      label,
      placeHolder,
      dateFormat = 'DD/MM/YYYY',
      maxDate,
      minDate,
      disabled,
      marginTop,
      error
    } = props;

    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const [focussed, setFocussed] = useState<boolean>(false);
    const noOfYearsToDate = moment().diff(
      moment(field.value).format('YYYY-MM-DD'),
      'years'
    );
    const noOfDaysToDate = moment().diff(
      moment(field.value).format('YYYY-MM-DD'),
      'days'
    );
    const init = useRef<boolean>(false);
    const labelRef = useRef(null);
    const [labelTop, setLabelTop] = useState(12);

    /**
     * Positioning no of years label using height of the field label
     * doing this inorder to avoid editing material ui component
     */
    useEffect(() => {
      if (labelRef && labelRef.current !== null) {
        const label = labelRef.current || { clientHeight: 12 };
        const { clientHeight } = label;
        setLabelTop(clientHeight + 22);
      }
    }, [labelRef]);

    const performInitialValidation = () => {
      /**
       * incase of invalid date set in formik state component may throw an error,
       *  Checking and updating value to null incase the previous value was invalid
       */

      if (!init.current) {
        if (field.value && field.value.isValid && !field.value.isValid()) {
          return null;
        }
        init.current = true;
      }
      return field.value;
    };

    return (
      <div
        className={classes.datePickerRootContainer}
        style={marginTop ? { marginTop } : undefined}
      >
        <InputLabel ref={labelRef} classes={{ root: classes.datePickerLabel }}>
          {label}
        </InputLabel>
        <KeyboardDatePicker
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          margin="none"
          id={`date-picker-dialog-${name}`}
          format={dateFormat}
          value={performInitialValidation()}
          onChange={(date: MaterialUiPickersDate) => {
            helpers.setValue(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'Change date'
          }}
          onFocus={() => {
            setFocussed(true);
          }}
          onBlur={(e) => {
            field.onBlur(e);
            helpers.setTouched(true);
            setFocussed(false);
          }}
          inputVariant="outlined"
          className={clsx(
            classes.datePickerContainer,
            field.value &&
              field.value.isValid &&
              field.value.isValid() &&
              !meta.error &&
              !focussed
              ? classes.textInputFilled
              : null
          )}
          InputProps={{
            classes: {
              root: classes.datePickerRoot,
              disabled: classes.textInputDisabled
            },
            placeholder: placeHolder
          }}
          helperText={
            meta.error && meta.touched ? meta.error : error ? error : ''
          }
          keyboardIcon={
            // <CalendarTodayOutlined classes={{ root: classes.datePickerIcon }} />
            null
          }
          error={(meta.error && meta.touched) || error ? true : false}
          InputAdornmentProps={{
            classes: { root: classes.inputAdornment }
          }}
        />
        {field.value && !isNaN(noOfYearsToDate) && (
          <div className={clsx(classes.ageLabel)} style={{ top: labelTop }}>
            {noOfYearsToDate > 0 ? noOfYearsToDate : noOfDaysToDate}{' '}
            {noOfYearsToDate > 0
              ? noOfYearsToDate !== 1
                ? 'years'
                : 'year'
              : noOfDaysToDate !== 1
              ? 'days'
              : 'day'}
          </div>
        )}
      </div>
    );
  }
);

export { DatePicker };
