import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField
} from 'formik';
import clsx from 'clsx';
import { ValidationIcon } from './ValidationIcon';
import { find } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  selectFieldContainer: {
    marginTop: theme.spacing(3)
  },
  selectContainer: {
    width: '100%',
    flex: 1
  },
  selectInputRoot: {
    background: 'white',
    width: '100%',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  selectDisabled: {
    color: theme.palette.grey[400]
  },
  selectInputRootDisabled: (props) => {
    return {
      background: 'white',
      width: '100%',
      color: 'gray',
      '&:focus': {
        backgroundColor: 'white'
      }

      // '&:before': {
      //   //@ts-ignore
      //   content: `"${props.placeholder}"`,
      //   position: 'absolute',
      //   top: 12
      // }
    };
  },
  selectLabel: {
    marginBottom: theme.spacing(1)
  },
  optionsTextTransform: {
    textTransform: 'capitalize'
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important'
  },
  selectBoxFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  selectPaper: {
    boxShadow: 'none',
    border: '1px solid #E8EAEB',
    marginTop: 4,
    '& .MuiMenu-list': {
      paddingTop: 0
    },
    maxHeight: 300
  },
  noneOptionRoot: {
    fontSize: '12px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: '#F7F9FA',
    color: '#BFC4C6',
    display: 'none'
  }
}));

interface HeightPickerProps {
  ftName: string;
  inName: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  defaultselected?: string;
}

interface HeightOption {
  label: string;
  value: string;
  parts: { feet: number; inch: number };
}

const getHeightOptions = (): [HeightOption] => {
  const feetSet = [1, 2, 3, 4, 5, 6, 7];
  const inchSet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const options: any = [];
  feetSet.forEach((feet) => {
    inchSet.forEach((inch) => {
      options.push({
        label: `${feet} feet ${inch} inch`,
        value: `${feet}:${inch}`,
        parts: { feet, inch }
      });
    });
  });
  return options;
};

const HeightPicker: React.FC<HeightPickerProps> = React.memo(
  (props: HeightPickerProps) => {
    const {
      ftName,
      inName,
      label,
      placeholder,
      disabled,
      defaultselected
    } = props;

    const feet: [
      FieldInputProps<any>,
      FieldMetaProps<any>,
      FieldHelperProps<any>
    ] = useField(ftName);
    const inch: [
      FieldInputProps<any>,
      FieldMetaProps<any>,
      FieldHelperProps<any>
    ] = useField(inName);

    const feetField = feet[0];
    const feetMeta = feet[1];
    const feetHelper = feet[2];

    const inchField = inch[0];
    const inchMeta = inch[1];
    const inchHelper = inch[2];

    const classes = useStyles({ placeholder });

    const [focussed, setFocussed] = useState<boolean>();
    const [selected, setSelected] = useState<any>(
      feetField.value !== undefined && inchField.value !== undefined
        ? `${feetField.value}:${inchField.value}`
        : 'none'
    );

    const options = getHeightOptions();

    const handleChange = (
      event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
      if (event.target.value !== 'none') {
        setSelected(event.target.value as string);
        const option: any = find(
          options,
          (o: HeightOption) => o.value === event.target.value
        );
        if (option) {
          feetHelper.setValue(option.parts.feet);
          inchHelper.setValue(option.parts.inch);
        }
      }
    };

    const shouldAutoFocus = (value: string) => {
      return selected === 'none' && value === '5:10';
    };

    const hasError =
      (feetMeta.error && feetMeta.touched) ||
      (inchMeta.error && inchMeta.touched);

    return (
      <div
        className={clsx(
          classes.selectFieldContainer,
          selected !== 'none' && !feetMeta.error && !inchMeta.error && !focussed
            ? classes.selectBoxFilled
            : undefined
        )}
      >
        <InputLabel classes={{ root: classes.selectLabel }}>{label}</InputLabel>
        <Select
          displayEmpty
          disabled={disabled}
          id={`select-${ftName}-${inName}`}
          name={`${ftName}-${inName}`}
          fullWidth
          value={defaultselected ? defaultselected : selected}
          onChange={handleChange}
          variant="outlined"
          classes={{
            root:
              selected !== 'none'
                ? classes.selectInputRoot
                : classes.selectInputRootDisabled,
            disabled: classes.textInputDisabled
          }}
          error={
            (feetMeta.error && feetMeta.touched) ||
            (inchMeta.error && inchMeta.touched)
              ? true
              : false
          }
          onFocus={() => setFocussed(true)}
          onBlur={(e) => {
            setFocussed(false);
            feetField.onBlur(e);
            inchField.onBlur(e);
            feetHelper.setTouched(true);
            inchHelper.setTouched(true);
          }}
          IconComponent={ExpandMoreIcon}
          inputProps={{
            autoComplete: 'nope'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            elevation: 0,
            classes: {
              paper: classes.selectPaper
            }
          }}
        >
          <MenuItem value="none" classes={{ root: classes.noneOptionRoot }}>
            {placeholder}
          </MenuItem>
          {options.map((option: any, index: number) => {
            return (
              <MenuItem
                className={classes.optionsTextTransform}
                key={index}
                value={typeof option === 'object' ? option.value : option}
                autoFocus={shouldAutoFocus(option.value)}
              >
                {typeof option === 'object'
                  ? option.label
                    ? option.label
                    : option.value
                  : option}
              </MenuItem>
            );
          })}
        </Select>
        {hasError ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>
              {feetMeta.error
                ? feetMeta.error
                : inchMeta.error
                ? inchMeta.error
                : ''}
            </FormHelperText>
          </Box>
        ) : null}
      </div>
    );
  }
);

export { HeightPicker };
