import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      fontSize: '14px',
      lineHeight: '20px'
    },
    buttonTextPrimary: {
      color: theme.palette.text.primary
    },
    buttonRoot: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px}`
    }
  })
);

interface ButtonGroupSwitchProps {
  selected?: string;
  onChange: (selected: string) => void;
  labels: string[];
  disabled?: boolean;
}

const ButtonGroupSwitch: React.FC<ButtonGroupSwitchProps> = ({
  selected,
  onChange,
  labels,
  disabled
}) => {
  const classes = useStyles();
  return (
    <ButtonGroup
      color="primary"
      variant="outlined"
      size="small"
      disabled={disabled}
    >
      {labels.map((label, index) => {
        return (
          <Button
            key={index}
            variant={label === selected ? 'contained' : undefined}
            onClick={() => {
              if (label !== selected) {
                onChange(label);
              }
            }}
            disableElevation
            size="small"
            classes={{
              root: classes.buttonRoot,
              label: clsx(
                classes.buttonText,
                label !== selected ? classes.buttonTextPrimary : ''
              )
            }}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export { ButtonGroupSwitch };
