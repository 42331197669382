import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Popover,
  Icon
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Link, useNavigate } from 'react-router-dom';
import { Akshath, Logo, HelpIcon } from '../../res/img';
import { getLoggedInUser } from '../../utils';
import { useAuth } from '../../context/AuthProvider';
import { Button } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      background: '#EEFAFF'
    },
    root: {
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        height: '64px'
      },
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: 0
      }
    },
    gutters: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
      }
    },
    title: {
      flexGrow: 1
    },
    appbarRoot: {
      background: theme.palette.common.white,
      paddingLeft: 'calc(100vw - 100%)',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
      '@media(max-width:1024px)': {
        paddingLeft: '16px !important',
        paddingRight: '16px !important'
      },
      [theme.breakpoints.only('sm')]: {
        paddingLeft: '16px !important',
        paddingRight: '16px !important'
      },
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none'
      },
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
      }
    },
    appbarContainer: {
      position: 'relative'
    },
    logo: {
      width: 68,
      height: 32
    },
    headerTitle: {
      '@media(max-width:320px)': {
        fontSize: '0.75rem'
      }
    },
    headerButtonText: {
      fontSize: '14px',
      color: theme.palette.grey[700],
      fontWeight: 500,
      lineHeight: '18px'
    }
  })
);

interface HeaderProps {
  headerLeft?: any;
  headerRight?: any;
  headerTitle?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { headerLeft, headerRight, headerTitle } = props;

  const classes = useStyles();
  const user = getLoggedInUser();
  const auth = useAuth();
  const rm = auth?.user?.rm;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.container}>
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        classes={{ root: classes.appbarRoot }}
      >
        <Container classes={{ root: classes.appbarContainer }} maxWidth={false}>
          <Toolbar classes={{ root: classes.root, gutters: classes.gutters }}>
            <Grid alignItems="center" container justifyContent="space-between">
              <Grid item>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {headerLeft && React.cloneElement(headerLeft)}
                  <Hidden mdUp>
                    <Link to={'/'}>
                      <img src={Logo} className={classes.logo} alt="Ditto" />
                    </Link>
                  </Hidden>
                  {!headerTitle ? (
                    <Hidden smDown>
                      <Link to={'/'}>
                        <img src={Logo} className={classes.logo} alt="Ditto" />
                      </Link>
                    </Hidden>
                  ) : (
                    <>
                      <Hidden mdUp>
                        <Typography
                          variant="h6"
                          classes={{ root: classes.headerTitle }}
                        >
                          {headerTitle}
                        </Typography>
                      </Hidden>
                      <Hidden smDown>
                        <Link to="/">
                          <img src={Logo} className={classes.logo} alt="Ditto" />
                        </Link>
                      </Hidden>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item>
                {headerRight ? React.cloneElement(headerRight) : null}
                {user && (
                  <>
                    <Button
                      color="primary"
                      startIcon={
                        <Icon>
                          <img src={HelpIcon} height={18} width={18} />
                        </Icon>
                      }
                      onClick={(e: any) => {
                        // trackEvent(HEADER_HELP_BUTTON_CLICKED, {
                        //   page: location.pathname
                        // });
                        handleClick(e);
                      }}
                    >
                      <Box
                        component="span"
                        fontWeight="bold"
                        className={classes.headerButtonText}
                      >
                        Help
                      </Box>
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Box
                        paddingX={2}
                        paddingY={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        minWidth={240}
                      >
                        <Typography variant="body1">Hey 👋 Contact me</Typography>
                        <Box mt={2.4} mb={1.6}>
                          <img
                            src={Akshath}
                            style={{ width: 64, height: 64, borderRadius: '50%' }}
                          />
                        </Box>
                        {rm?.full_name && (
                          <Typography variant="body1" gutterBottom>
                            <Box component="span" fontWeight="500">
                              {rm.full_name}
                            </Box>
                          </Typography>
                        )}
                        {rm?.email && (
                          <Typography
                            variant="body2"
                            gutterBottom
                            color="textSecondary"
                          >
                            <Box component="span" fontWeight="400">
                              {rm.email}
                            </Box>
                          </Typography>
                        )}
                        {rm?.phone_num && (
                          <Typography
                            variant="body2"
                            gutterBottom
                            color="textSecondary"
                          >
                            <Box component="span" fontWeight="400">
                              {rm.phone_num}
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Popover>
                  </>
                )}
                {/* {user && (
                  <>
                    <IconButton aria-describedby={id} onClick={handleClick}>
                      <Avatar alt="Akshath Srivastava" src={Akshath} />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Box paddingX={2} paddingY={1.2}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => {
                            auth.signout(() => {
                              navigate('/');
                            });
                          }}
                        >
                          Logout
                        </Button>
                      </Box>
                    </Popover>
                  </>
                )} */}
              </Grid>
            </Grid>
            <Grid item />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export { Header };
