import React, { useState, useRef, useEffect } from 'react';
import { TextField, InputLabel, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from 'formik';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatNoDecimal(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      prefix=""
      decimalScale={0}
    />
  );
}

function NumberFormatWithDecimal(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      allowLeadingZeros={true}
      prefix=""
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(3)
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important'
  },
  textFieldFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  inputRoot: {
    background: 'white'
  },
  inputRootAutoHeight: {
    height: 'auto !important'
  }
}));

interface TextInputProps {
  name: string;
  placeholder: string;
  label?: string;
  max?: number;
  min?: number;
  typeNumber?: boolean;
  disableEnterKey?: boolean;
  enableDecimal?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  errorText?: string;
  disabled?: boolean;
  multiline?: boolean;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  numbersOnly?: boolean; // for text with numbers only allows zeros at the beginning eg: pincode,phone no, otp etc
  alphabetOnly?: boolean;
  showZero?: boolean;
  onClickKnowMore?: () => void;
  password?: boolean;
  noMargin?: boolean;
}

const TextInput: React.FC<TextInputProps> = React.memo((props: TextInputProps) => {
  const {
    name,
    label,
    max,
    min,
    typeNumber,
    enableDecimal,
    autoFocus,
    placeholder,
    disabled,
    inputProps,
    multiline,
    numbersOnly,
    alphabetOnly,
    showZero,
    password,
    noMargin,
    onClickKnowMore
  } = props;

  const classes = useStyles();

  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [focussed, setFocussed] = useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const notSingleLine = inputProps && inputProps.rows && inputProps.rows > 0;

  useEffect(() => {
    /*
        in case of typeNumber, 0 is considered a correct value by formik validation for required but we have
        to show empty input and onblur show required error so setting 0 to '' for typenumber in formik
      */
    if (showZero) return;
    if (typeNumber && field.value === 0) {
      helpers.setValue('');
    }
  }, []);

  const adjustMaxMin = (val: any) => {
    let numVal = val;
    if (max && numVal >= max) {
      numVal = max;
    } else if (min && numVal <= min) {
      numVal = min;
    }
    return numVal;
  };

  return (
    <div className={!noMargin ? classes.textFieldContainer : undefined}>
      <InputLabel>
        {label}{' '}
        {onClickKnowMore && (
          <Box
            color="#3DABF5"
            component="span"
            style={{ cursor: 'pointer' }}
            onClick={onClickKnowMore}
          >
            {' '}
            Know more
          </Box>
        )}
      </InputLabel>
      <TextField
        id={`text-input-${name}`}
        name={field.name}
        classes={{
          root: clsx(
            field.value && !meta.error && !focussed ? classes.textFieldFilled : null
          )
        }}
        inputRef={inputRef}
        autoFocus={autoFocus}
        variant="outlined"
        fullWidth
        value={
          showZero
            ? field.value !== undefined
              ? field.value
              : ''
            : field.value
            ? field.value
            : ''
        }
        onBlur={(e) => {
          field.onBlur(e);
          // check if there is any digits after . in case of enable decimal if not remove . from number
          if (typeNumber && field.value) {
            if (enableDecimal) {
              const numVal = field.value;
              const parts = numVal.toString().split('.');
              if (!parts[1]) {
                helpers.setValue(adjustMaxMin(Number(parts[0])));
              } else {
                helpers.setValue(adjustMaxMin(numVal));
              }
            } else {
              // remove unwanted spaces at the end
              helpers.setValue(
                field.value
                  ? adjustMaxMin(Number(field.value.toString().trim()))
                  : field.value
              );
            }
          } else {
            // remove unwanted spaces at the end
            helpers.setValue(
              field.value && field.value.trim ? field.value.trim() : field.value
            );
          }
          setReadOnly(true);
          setFocussed(false);
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          /*
              read only not supporting on IOS safari.
              as a quick fix handled with preventing value update
            */
          if (readOnly) return;

          let val: any = event.target.value;

          if (typeNumber && val) {
            if (!enableDecimal) {
              val = val ? val.replace(/[^0-9]/g, '') : '';
              helpers.setValue(
                val ? adjustMaxMin(Number(val.toString().trim())) : val
              );
            } else {
              helpers.setValue(val ? adjustMaxMin(val.toString().trim()) : val);
            }
          } else if (numbersOnly && val) {
            val = val ? val.replace(/[^0-9]/g, '') : '';
            helpers.setValue(val);
          } else {
            helpers.setValue(!alphabetOnly ? val : val.replace(/[^a-zA-Z]/g, ''));
          }
        }}
        disabled={disabled}
        onFocus={() => {
          setReadOnly(false);
          setFocussed(true);
        }}
        onKeyPress={(e) => null}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          'aria-labelledby': 'input',
          classes: {
            root: clsx(
              classes.inputRoot,
              notSingleLine ? classes.inputRootAutoHeight : null
            ),
            disabled: classes.textInputDisabled
          },
          inputComponent: typeNumber
            ? enableDecimal
              ? (NumberFormatWithDecimal as any)
              : (NumberFormatNoDecimal as any)
            : undefined,
          type: password ? 'password' : undefined
        }}
        inputProps={{ ...inputProps }}
        helperText={meta.error && meta.touched ? `${meta.error}` : ''}
        error={meta.error && meta.touched ? true : false}
        placeholder={placeholder}
        multiline={multiline}
      />
    </div>
  );
});

TextInput.defaultProps = {
  autoFocus: false,
  disableEnterKey: false,
  inputProps: {},
  multiline: false
};

export { TextInput };
