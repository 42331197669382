import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

import { useField } from 'formik';
import { FormRow } from '../spacing';
import { useResponsive } from '../../theme/styles';
import { ValidationIcon } from './ValidationIcon';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  switcherButtonRoot: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',
    border: '1px solid #D9DDDE',
    [theme.breakpoints.up('md')]: {
      height: 54,
      paddingTop: '12px',
      paddingBottom: '12px'
    }
  },
  switcherButtonLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    },
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  switcherButtonLabelSelected: {
    color: theme.palette.primary.main
  },
  switcherButtonRootCol2: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  hasRightLabel: {
    justifyContent: 'space-between !important'
  },
  labelRight: {
    fontSize: 14,
    fontWeight: 400,
    color: '#666666',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelLeftAlign: {
    justifyContent: 'flex-start'
  },
  labelRightWithTick: {
    marginRight: theme.spacing(1 / 2)
  }
}));

const styles = {
  active: {
    background: '#F7FBFE',
    border: '2px solid #3DABF5'
  },
  inactive: {
    background: 'white'
  }
};

interface Option {
  label: string;
  value: string | number;
  labelRight?: string;
}

interface MultiSelectButtonsProps {
  name: string;
  options: Option[];
  label: string;
  columns?: 1 | 2;
  onChange?: (value: Record<string, unknown>) => void;
  showTick?: boolean;
  showRightLabel?: boolean;
  leftAlignLabel?: boolean;
  singleSelect?: boolean;
}

const MultiSelectButtons: React.FC<MultiSelectButtonsProps> = React.memo(
  ({
    name,
    options,
    label,
    columns,
    showRightLabel,
    showTick,
    leftAlignLabel,
    onChange,
    singleSelect = false
  }: MultiSelectButtonsProps) => {
    const [field, meta, helpers] = useField(name);
    const classes = useStyles();
    const r = useResponsive();

    const renderValueOption = (option: Option, index: number) => {
      const isSelected = field.value.length
        ? field.value.includes(option.value)
        : false;
      return (
        <Grid
          key={index}
          item
          md={columns === 2 ? 6 : 12}
          sm={columns === 2 ? 6 : 12}
          xs={12}
        >
          <Button
            classes={{
              root: clsx(
                classes.switcherButtonRoot,
                columns === 2 ? classes.switcherButtonRootCol2 : null
              ),
              label: clsx(
                classes.switcherButtonLabel,
                isSelected ? classes.switcherButtonLabelSelected : null,
                showRightLabel || showTick ? classes.hasRightLabel : null,
                leftAlignLabel ? classes.labelLeftAlign : null
              )
            }}
            style={!isSelected ? styles.inactive : styles.active}
            size="large"
            fullWidth
            variant={!isSelected ? 'outlined' : 'contained'}
            onClick={() => {
              if (!field.value.includes(option.value)) {
                helpers.setValue([...field.value, option.value]);
              } else {
                helpers.setValue(
                  field.value.filter((e: any) => e !== option.value)
                );
              }
              helpers.setTouched(true, false);
            }}
            color={!isSelected ? 'default' : 'primary'}
            disableElevation
          >
            {option.label}
            {option.labelRight && showRightLabel && (
              <Box
                component="span"
                className={clsx(
                  classes.labelRight,
                  showTick && isSelected ? classes.labelRightWithTick : null
                )}
              >
                {option.labelRight}
                {showTick && isSelected && (
                  <Box
                    component="span"
                    display="flex"
                    height={24}
                    width={30}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                  </Box>
                )}
              </Box>
            )}
            {!option.labelRight && showTick && isSelected && (
              <Box
                component="span"
                display="flex"
                height={24}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
              </Box>
            )}
          </Button>
        </Grid>
      );
    };

    const renderValueOptionBase = (option: Option, index: number) => {
      const isSelected = singleSelect
        ? option.value === field.value
        : field.value && Object.keys(field.value).length
        ? field.value[option.value] === true
        : false;
      return (
        <Grid
          key={`${name + '-' + index}-multi-select-option`}
          item
          md={columns === 2 ? 6 : 12}
          sm={columns === 2 ? 6 : 12}
          xs={12}
        >
          <Button
            classes={{
              root: clsx(
                classes.switcherButtonRoot,
                columns === 2 ? classes.switcherButtonRootCol2 : null
              ),
              label: clsx(
                classes.switcherButtonLabel,
                isSelected ? classes.switcherButtonLabelSelected : null,
                showRightLabel || showTick ? classes.hasRightLabel : null,
                leftAlignLabel ? classes.labelLeftAlign : null
              )
            }}
            style={!isSelected ? styles.inactive : styles.active}
            size="large"
            fullWidth
            variant={!isSelected ? 'outlined' : 'contained'}
            onClick={() => {
              if (
                field.value &&
                (field.value[option.value] === '' ||
                  field.value[option.value] === undefined ||
                  field.value[option.value] === false)
              ) {
                const value = singleSelect
                  ? option.value
                  : { ...field.value, [option.value]: true };
                onChange ? onChange(value) : helpers.setValue(value);
              } else {
                const value = singleSelect
                  ? option.value
                  : { ...field.value, [option.value]: false };
                onChange ? onChange(value) : helpers.setValue(value);
              }
              helpers.setTouched(true, false);
            }}
            color={!isSelected ? 'default' : 'primary'}
            disableElevation
          >
            {option.label}
            {option.labelRight && showRightLabel && (
              <Box component="span" className={clsx(classes.labelRight)}>
                {showTick && isSelected && (
                  <Box
                    component="span"
                    display="flex"
                    height={24}
                    width={30}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                  </Box>
                )}
                {option.labelRight}
              </Box>
            )}
            {!option.labelRight && showTick && isSelected && (
              <Box
                component="span"
                display="flex"
                height={24}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
              </Box>
            )}
          </Button>
        </Grid>
      );
    };

    const renderRowOfTwo = (index: number) => {
      return (
        <FormRow key={index}>
          {Array.isArray(field.value)
            ? renderValueOption(options[index - 1], index - 1)
            : renderValueOptionBase(options[index - 1], index - 1)}
          {Array.isArray(field.value)
            ? renderValueOption(options[index], index)
            : renderValueOptionBase(options[index], index)}
        </FormRow>
      );
    };

    const renderRowOfOne = (index: number) => {
      return (
        <FormRow key={index}>
          {Array.isArray(field.value)
            ? renderValueOption(options[index], index)
            : renderValueOptionBase(options[index], index)}
        </FormRow>
      );
    };

    return (
      <div className={classes.textFieldContainer}>
        <Box marginBottom={1.5}>
          <Typography variant="body2" color="textSecondary">
            <Box component="span" fontWeight={500}>
              {label}
            </Box>
          </Typography>
        </Box>
        <Grid
          container
          spacing={!columns || columns === 1 ? 1 : r({ md: 2, sm: 2, xs: 0 })}
        >
          {options.map((option: Option, index: number) => {
            if (columns === 2) {
              if (index % 2 === 1) {
                return renderRowOfTwo(index);
              } else if (index % 2 === 0 && index === options.length - 1) {
                return renderRowOfOne(index);
              }
            } else {
              return renderRowOfOne(index);
            }
          })}
        </Grid>
        {meta.error && meta.touched && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { MultiSelectButtons };
