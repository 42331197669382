import React, { ReactElement } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Box, Typography } from '@material-ui/core';

interface DittoAlertProps {
  type: 'success' | 'warning' | 'info' | 'error';
  message: string;
  customIcon?: ReactElement;
  dangerousHtml?: boolean;
}

const DittoAlert: React.FC<DittoAlertProps> = ({
  type,
  message,
  customIcon,
  dangerousHtml
}) => {
  const getColors = () => {
    let bgColor = '#FEF4E2';
    let iconColor = '#966316';

    if (type === 'warning') {
      bgColor = '#FEF4E2';
      iconColor = '#966316';
    } else if (type === 'success') {
      bgColor = 'rgb(239, 247, 233)';
      iconColor = 'rgb(91, 158, 40)';
    } else if (type === 'info') {
      bgColor = '#F7F9FA';
      iconColor = '#3DABF5';
    } else if (type === 'error') {
      bgColor = '#FAE6E6';
      iconColor = '#F24C4C';
    }

    return { bgColor, iconColor };
  };

  const colors = getColors();

  return (
    <Box
      bgcolor={colors.bgColor}
      borderRadius={8}
      paddingX={1.2}
      paddingY={0.8}
      display="flex"
      flexDirection="row"
    >
      {!customIcon ? (
        <ErrorOutlineIcon
          style={{
            width: 20,
            height: 20,
            color: colors.iconColor,
            marginRight: 10
          }}
        />
      ) : (
        customIcon
      )}
      <Typography variant="caption">
        {!dangerousHtml ? (
          message
        ) : (
          <Box component="span" dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </Typography>
    </Box>
  );
};

export { DittoAlert };
