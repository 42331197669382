import packageJson from '../package.json';
export const APP_STAGE_DEVELOPMENT = 'develop';
export const APP_STAGE_PRODUCTION = 'production';
export const APP_STAGE_STAGING = 'staging';

type AppConfig = {
  APP_VERSION?: string;
  APP_STAGE?: string;
  PARTNER_API?: string;
};

const config: AppConfig = {
  APP_VERSION: packageJson.version,
  APP_STAGE: process.env.REACT_APP_STAGE,
  PARTNER_API: process.env.REACT_APP_PARTNER_API
};

export default { ...config };
