import React, { useState, useRef } from 'react';
import { TextField, InputLabel, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(3)
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important'
  },
  textFieldFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  inputRoot: {
    background: 'white',
    height: 44
  },
  centerText: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    }
  }
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatNoDecimal(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      prefix=""
      decimalScale={0}
    />
  );
}

function NumberFormatWithDecimal(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      prefix=""
    />
  );
}

interface TextInputProps {
  name: string;
  placeholder: string;
  value: any;
  onChange: (arg1: any) => void;
  onBlur?: (arg1: any) => void;
  onFocus?: () => void;
  label?: string;
  max?: number;
  min?: number;
  typeNumber?: boolean;
  disableEnterKey?: boolean;
  enableDecimal?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  errorText?: string;
  touched?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  startAdornment?: any;
  inputAdornment?: any;
  marginTop?: number;
  centerText?: boolean;
  moneyAdornment?: boolean;
  numbersOnly?: boolean; // for text with numbers only allows zeros at the beginning eg: pincode,phone no, otp etc
}

const BasicTextInput: React.FC<TextInputProps> = React.memo(
  (props: TextInputProps) => {
    const {
      name,
      label,
      max,
      min,
      value,
      onChange,
      onBlur,
      onFocus,
      typeNumber,
      enableDecimal,
      autoFocus,
      placeholder,
      disabled,
      touched,
      inputProps,
      multiline,
      errorText,
      inputAdornment,
      startAdornment,
      marginTop,
      centerText,
      moneyAdornment,
      numbersOnly
    } = props;

    const classes = useStyles();

    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [focussed, setFocussed] = useState<boolean>(false);

    const inputModeProp = typeNumber ? 'numeric' : 'text';

    const adjustMaxMin = (val: any) => {
      let numVal = val;
      if (max && numVal >= max) {
        numVal = max;
      } else if (min && numVal <= min) {
        numVal = min;
      }
      return numVal;
    };

    return (
      <div
        className={classes.textFieldContainer}
        style={marginTop !== undefined ? { marginTop: marginTop } : undefined}
      >
        {label ? <InputLabel>{label}</InputLabel> : null}
        <TextField
          id={`text-input-${name}`}
          name={name}
          classes={{
            root: clsx(
              value && !errorText && !focussed ? classes.textFieldFilled : null
            )
          }}
          inputRef={inputRef}
          autoFocus={autoFocus}
          variant="outlined"
          fullWidth
          //margin="dense"
          value={value ? value : ''}
          onBlur={(e) => {
            if (typeNumber && value) {
              if (enableDecimal) {
                const numVal = value;
                const parts = numVal.toString().split('.');
                if (!parts[1]) {
                  onChange(adjustMaxMin(Number(parts[0])));
                } else {
                  onChange(adjustMaxMin(numVal));
                }
              } else {
                // remove unwanted spaces at the end
                onChange(
                  value ? adjustMaxMin(Number(value.toString().trim())) : value
                );
              }
            } else {
              // remove unwanted spaces at the end
              onChange(value ? value.trim() : value);
            }
            setReadOnly(true);
            setFocussed(false);
            onBlur && onBlur(e.target.value);
            setReadOnly(true);
            setFocussed(false);
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            /*
          read only not supporting on IOS safari.
          as a quick fix handled with preventing value update
          */
            if (readOnly) return;

            const val: any = event.target.value;

            if (typeNumber && val) {
              onChange(val);
            } else if (numbersOnly && val) {
              onChange(val.replace(/[^0-9]/g, ''));
            } else {
              onChange(val);
            }
          }}
          disabled={disabled}
          onFocus={() => {
            setReadOnly(false);
            setFocussed(true);
            onFocus && onFocus();
          }}
          onKeyPress={(e) => null}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            'aria-labelledby': 'input',
            inputMode: inputModeProp,
            classes: {
              root: clsx(classes.inputRoot, centerText ? classes.centerText : null),
              disabled: classes.textInputDisabled
            },
            endAdornment: inputAdornment,
            startAdornment: moneyAdornment ? (
              <InputAdornment position="start">₹</InputAdornment>
            ) : startAdornment ? (
              startAdornment
            ) : null,
            inputComponent: typeNumber
              ? !enableDecimal
                ? (NumberFormatNoDecimal as any)
                : (NumberFormatWithDecimal as any)
              : undefined
          }}
          inputProps={{
            ...inputProps
            // endAdornment: inputAdornment,
            // startAdornment: moneyAdornment ? (
            //   <InputAdornment position="start">₹</InputAdornment>
            // ) : undefined
          }}
          helperText={errorText && touched ? errorText : ''}
          error={errorText && touched ? true : false}
          placeholder={placeholder}
          multiline={multiline}
        />
      </div>
    );
  }
);

BasicTextInput.defaultProps = {
  autoFocus: false,
  disableEnterKey: false,
  inputProps: {},
  multiline: false
};

export { BasicTextInput };
