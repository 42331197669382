import { Box, GridSize } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import React from 'react';

interface StepsProps {
  steps: string[];
  activeStep: number;
  hideLabels?: boolean;
  padForBackButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  stepBar: {
    height: '3px',
    width: '100%',
    background: theme.palette.grey[200]
  },
  stepBarActive: {
    background: theme.palette.primary.main
  },
  stepNumber: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    marginRight: 8,
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 16,
    color: theme.palette.grey[600],
    background: theme.palette.grey[100],
    borderRadius: 10,
    flexShrink: 0,
    '@media(max-width:320px)': {
      display: 'none'
    }
  },
  hideStepNumber: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  stepNumberActive: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },
  stepNumberCompleted: {
    background: 'rgba(61, 171, 245, 0.2)'
  },
  stepName: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(1.2),
    fontWeight: 500,
    lineHeight: '20px'
  },
  stepNameActive: {
    color: theme.palette.grey[900]
  }
}));

const Steps: React.FC<StepsProps> = React.memo(
  ({ steps, activeStep, hideLabels }) => {
    const classes = useStyles();
    const noOfSteps = steps.length ? steps.length : 12;
    const stepWidth: GridSize = (100 / noOfSteps) as GridSize;
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={2}
        width="100%"
      >
        {steps.map((step, index) => {
          const stepNumber = index + 1;
          const isActive = activeStep === stepNumber;
          const isCompleted = stepNumber < activeStep;
          return (
            <div
              key={index}
              style={{
                width: `${stepWidth}%`,
                marginRight: index !== steps.length - 1 ? '10px' : 0,
                boxSizing: 'border-box'
              }}
            >
              <div
                className={clsx(
                  classes.stepBar,
                  isActive || isCompleted ? classes.stepBarActive : null
                )}
              />
              {!hideLabels && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  marginTop={1.6}
                >
                  <div
                    className={clsx(
                      classes.stepNumber,
                      isActive ? classes.stepNumberActive : null,
                      isCompleted ? classes.stepNumberCompleted : null,
                      steps.length > 4 ? classes.hideStepNumber : null
                    )}
                  >
                    {!isCompleted ? (
                      index + 1
                    ) : (
                      <CheckIcon
                        fontSize="small"
                        htmlColor="#3DABF5"
                        style={{ padding: 2 }}
                      />
                    )}
                  </div>
                  <div
                    className={clsx(
                      classes.stepName,
                      isActive ? classes.stepNameActive : null
                    )}
                  >
                    {step}
                  </div>
                </Box>
              )}
            </div>
          );
        })}
      </Box>
    );
  }
);

export { Steps };
