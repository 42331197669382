import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useResponsive } from '../../theme/styles';

const FieldTip = ({ text }: { text: string }) => {
  const r = useResponsive();
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <Box
        borderLeft="3px solid #1FC9FF"
        paddingLeft={1}
        marginY={r({ xs: 1, sm: 1, md: 1.5 })}
      >
        <Typography
          variant={r({ md: 'body1', sm: 'body2', xs: 'body2' })}
          color="textSecondary"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export { FieldTip };
