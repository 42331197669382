import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { useResponsive } from '../theme/styles';
import { drawerWidth } from '../theme/constants';
import { DittoHotlineDrawer } from '../components';
import Logo from '../res/img/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    root: {
      display: 'flex'
    },
    appBar: {
      background: theme.palette.common.white,
      boxShadow: '0px 0px 25px rgba(119, 119, 119, 0.15)',
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: `100%`,
        marginRight: 0
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    appBarFull: {
      background: theme.palette.common.white,
      boxShadow: '0px 0px 25px rgba(119, 119, 119, 0.15)',
      width: '100%',
      marginRight: 0
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.up('md')]: {
        minHeight: 80
      }
    },
    toolBarRoot: {
      maxWidth: 1200,
      width: '100%',
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        height: 80
      }
    },
    content: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3)
      }
    },
    contentRoot: {
      width: 848,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      '@media (max-width: 320px)': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
      }
    },
    title: {
      flexGrow: 1
    },
    contentRootLg: {
      width: 960,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      '@media (max-width: 320px)': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
      }
    },
    gutters: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
      }
    },
    logo: {
      width: 160,
      height: 60,
      [theme.breakpoints.down('sm')]: {
        width: 102,
        height: 38
      }
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  })
);

interface DrawerAppLayoutProps {
  headerLeft?: string;
  headerRight?: string;
}

const DrawerAppLayout: React.FC<DrawerAppLayoutProps> = (props: any) => {
  const { headerLeft, headerRight } = props;

  const classes = useStyles();
  const r = useResponsive();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={r({
          md: classes.appBar,
          sm: classes.appBarFull,
          xs: classes.appBarFull
        })}
        elevation={0}
      >
        <Toolbar classes={{ root: classes.toolBarRoot }}>
          {!headerLeft ? null : React.cloneElement(headerLeft)}
          <Link to="/">
            <img src={Logo} className={classes.logo} alt="Ditto" />
          </Link>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {headerRight ? React.cloneElement(headerRight) : null}
          </div>
          <div className={classes.sectionMobile}>
            <Button
              color="primary"
              startIcon={<ContactSupportIcon htmlColor="#444444" />}
              onClick={() => {
                if (open) {
                  handleDrawerClose();
                } else {
                  handleDrawerOpen();
                }
              }}
            >
              <Typography variant="body1" color="textSecondary">
                <Box component="span" fontWeight="bold">
                  Help
                </Box>
              </Typography>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Container
        classes={{
          root:
            props.maxWidth === 'md'
              ? classes.contentRoot
              : classes.contentRootLg
        }}
      >
        <div className={classes.toolbar} />
        <div className={classes.content}>{props.children}</div>
      </Container>
      <DittoHotlineDrawer isOpen={open} handleDrawerClose={handleDrawerClose} />
    </div>
  );
};

export { DrawerAppLayout };
