import { createContext, useContext, useState } from 'react';

import { Partner } from '../types';
import { getLoggedInUser, setLoggedInUser } from '../utils';
import PartnerApi from '../services/apis/partner';

interface AuthContextType {
  user: Partner | null;
  authError: string | null;
  authInProgress: boolean;
  signin: (email: string, password: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<Partner | null>(getLoggedInUser());
  const [authError, setAuthError] = useState<string | null>(null);
  const [authInProgress, setAuthInProgress] = useState<boolean>(false);

  const signin = async (email: string, password: string, callback: VoidFunction) => {
    try {
      setAuthInProgress(true);
      const response = await PartnerApi.signIn(email, password);
      if (response.data) {
        const user = response.data;
        setUser(user);
        setLoggedInUser(user);
        setAuthError(null);
        callback();
      }
    } catch (e: any) {
      setAuthError(e.message);
    } finally {
      setAuthInProgress(false);
    }
  };

  const signout = (callback: VoidFunction) => {
    setUser(null);
    setLoggedInUser(null);
    callback();
  };

  const value = { user, authError, authInProgress, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
