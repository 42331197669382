import React from 'react';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fabRoot: {
    height: '38px',
    width: '38px',
    background: '#F2F4F5',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiSvgIcon-root': {
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(2.8),
      height: theme.spacing(2.8),
      minHeight: theme.spacing(2.8)
    }
  },
  fabIcon: {
    fontSize: '24px',
    fontWeight: 100,
    color: '#525454',
    '&:hover': {
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  backText: {
    fontSize: '13px',
    color: theme.palette.grey[700],
    fontWeight: 500,
    lineHeight: '16px'
  }
}));

interface BackButtonProps {
  small?: boolean;
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  small,
  onClick
}: BackButtonProps) => {
  const classes = useStyles();
  return (
    <Box
      onClick={small ? onClick : undefined}
      display="flex"
      flexDirection="row"
      alignItems={small ? 'center' : undefined}
    >
      <Fab
        classes={{
          root: classes.fabRoot
        }}
        color="primary"
        onClick={!small ? onClick : undefined}
      >
        <ChevronLeft className={classes.fabIcon} />
      </Fab>
      {small && (
        <Box marginLeft={0.8} marginRight={2}>
          <Typography classes={{ root: classes.backText }}>Back</Typography>
        </Box>
      )}
    </Box>
  );
};

export { BackButton };
