import React, { useState, useRef, useEffect } from 'react';
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { debounce } from 'lodash';

import { convertToINR } from '../../utils';
import { BasicTextInput } from './basic';
import { useResponsive } from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(1.5),
      lineHeight: 1
    },
    seeker: {},
    seekerRoot: {
      marginTop: theme.spacing(2.4),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1.5)
      }
    },
    seekerLabelsContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    margin: {
      height: theme.spacing(3)
    },
    maxLabel: {
      color: '#999999',
      fontSize: '0.75rem',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.6)
    },
    minLabel: {
      color: '#999999',
      fontSize: '0.75rem',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(0.6),
      display: 'flex',
      alignItems: 'baseline'
    },
    moneyMark: {
      backgroundColor: '#0BF940',
      height: 24,
      width: 4,
      marginTop: -10
    },
    ageMark: {
      width: '0px',
      height: '0px',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #0BF940',
      marginTop: 5,
      marginLeft: -10,
      background: 'tranparent',
      backgroundColor: 'tranparent'
    },
    toolTipRoot: {
      background: '#FFFFFF',
      boxShadow:
        '0px 0.5px 1px rgba(0, 0, 0, 0.06), 0px 1px 4px rgba(17, 43, 66, 0.1)',
      borderRdius: '6px',
      width: '60',
      padding: '6px 22px',
      fontSize: '12px',
      fontWeight: 500,
      color: theme.palette.primary.main
    },
    toolTipArrow: {
      color: 'white',
      '&::before': {
        boxShadow:
          '0px 0.5px 1px rgba(0, 0, 0, 0.06), 0px 1px 4px rgba(17, 43, 66, 0.1)'
      }
    },
    coverLabel: {
      background: '#F7F9FA',
      borderRadius: 8,
      height: theme.spacing(4.4),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: theme.spacing(3.8)
      }
    }
  })
);

const InsuranceSlider = withStyles({
  root: {
    color: '#1FC9FF',
    height: 6,
    paddingTop: 7,
    paddingBottom: 7
  },
  disabled: {
    '&.MuiSlider-thumb': {
      height: 20,
      width: 20,
      background: '#dadada',
      marginTop: -8,
      marginLeft: -8
    }
  },
  thumb: {
    height: 20,
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -8,
    marginLeft: -8,
    border: '1px solid #E8EAEB',
    backgroundColor: 'white',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: 'none'
      }
    },
    '& .bar': {
      height: 1,
      width: 6,
      backgroundColor: '#737575'
    },
    '&::after': {
      content: '""',
      width: 6,
      height: 1,
      background: '#737575',
      marginTop: 2,
      borderRadius: 40,
      position: 'initial'
    },
    '&::before': {
      content: '""',
      width: 6,
      height: 1,
      background: '#737575',
      borderRadius: 40
    }
  },
  active: {},
  valueLabel: {
    width: 60,
    left: 'calc(-50% + -12px)',
    top: 40,
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    textAlign: 'center',
    boxShadow:
      '0px 0.5px 1px rgba(0, 0, 0, 0.06), 0px 1px 4px rgba(17, 43, 66, 0.1)',
    borderRadius: 6,
    '& > span': {
      backgroundColor: 'white',
      transform: 'none',
      height: 28,
      width: 28,
      '&::after': {
        content: '""',
        height: 10,
        width: 20,
        background: 'white',
        position: 'absolute',
        top: 0
      },
      '&::before': {
        transform: 'rotate(45deg)',
        content: '""',
        height: 12,
        width: 12,
        background: 'white',
        position: 'absolute',
        top: '-4px',
        boxShadow:
          '0px 0.5px 1px rgb(0 0 0 / 6%), 0px 1px 4px rgb(17 43 66 / 10%)'
      },
      '& > span': {
        color: '#3DABF5',
        transform: 'none',
        fontSize: 12,
        fontWeight: 500,
        zIndex: 100
      }
    }
  },
  track: {
    height: 5
  },
  mark: {},
  rail: {
    height: 6,
    opacity: 0.5,
    backgroundColor: '#E8EAEB',
    borderRadius: 40
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'tranparent'
  },
  markLabel: {
    color: '#999999',
    marginTop: '8px',
    fontSize: '0.75rem',
    '@media (pointer: coarse)': {
      top: '32px'
    }
  }
})(Slider);

const valueLabelFormatted = (value: number) => {
  return convertToINR(value, 2).toString();
};

const valueLabelFormattedAge = (value: number) => {
  return `${value}`;
};

interface Marker {
  value: number;
  label: string;
}

interface SeekerProps {
  title?: string;
  subTitle?: string;
  name: string;
  max: number;
  min: number;
  value: number;
  step: number;
  markers?: Marker[];
  type?: 'money' | 'age';
  skipFrom?: number;
  disabled?: boolean;
  refresh?: boolean;
  hideTextInput?: boolean;
  onChange: (value: number) => void;
}

const Seeker: React.FC<SeekerProps> = React.memo(
  ({
    title,
    subTitle,
    name,
    max,
    min,
    value,
    step,
    markers,
    type,
    skipFrom,
    disabled,
    refresh,
    hideTextInput,
    onChange
  }: SeekerProps) => {
    const r = useResponsive();
    const classes = useStyles();
    const [localValue, setLocalValue] = useState<number>(value ? value : min);
    const isTextFocussed = useRef<boolean>(false);

    useEffect(() => {
      if (refresh) {
        setLocalValue(value);
      }
    }, [refresh]);

    useEffect(() => {
      setLocalValue(value ? value : min);
    }, [value]);

    const debouncedOnChange = useRef(
      debounce((currValue: number) => {
        onChange(currValue);
      }, 500)
    );

    const handleFocus = () => {
      isTextFocussed.current = true;
    };

    const sanitizeValue = (input: any) => {
      if (!input || input === '') {
        return 0;
      } else if (typeof input === 'number') {
        return input;
      } else if (typeof input === 'string') {
        return parseInt(input.replace(/,/g, ''));
      } else {
        return input;
      }
    };

    const handleBlur = (input: any) => {
      let finalVal = min;
      const val = sanitizeValue(input);
      if (val <= min) {
        finalVal = min;
      } else if (val >= max) {
        finalVal = max;
      } else {
        finalVal = val;
      }

      if (value !== localValue) {
        setLocalValue(finalVal);
        debouncedOnChange.current(finalVal);
      }
      isTextFocussed.current = false;
    };

    const handleChange = (
      event: React.ChangeEvent<any> | null,
      value: number | number[]
    ) => {
      let finalVal = min;
      if (typeof value === 'number') {
        if (type === 'age' && skipFrom) {
          if (value > skipFrom) {
            finalVal = max;
          } else {
            finalVal = value;
          }
        } else {
          finalVal = value;
        }
      } else if (typeof value === 'string') {
        if (value === '') {
          finalVal = 0;
        } else if (parseInt(value) > 0) {
          finalVal = parseInt(value);
        }
      }
      setLocalValue(finalVal);
      if (!isTextFocussed.current) {
        debouncedOnChange.current(finalVal);
      }
    };

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            md={type === 'money' ? 7 : 9}
            sm={type === 'money' ? 7 : 9}
            xs={type === 'money' ? (!hideTextInput ? 12 : 7) : 9}
          >
            <Typography
              id={`seeker-${name}`}
              variant="body1"
              color="textPrimary"
            >
              <Box fontWeight="fontWeightMedium" component="span">
                {title}
              </Box>
            </Typography>
            <Box marginTop={0.6}>
              <Typography variant="body2" color="textSecondary">
                {subTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={type === 'money' ? 5 : 3}
            sm={type === 'money' ? 5 : 3}
            xs={type === 'money' ? (!hideTextInput ? 12 : 5) : 3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
              height="100%"
              marginTop={r({ xs: !hideTextInput ? 1.4 : 0, sm: 0, md: 0 })}
            >
              <Box
                width={
                  type === 'age' ? 60 : r({ xs: '100%', sm: '70%', md: '70%' })
                }
              >
                {!hideTextInput ? (
                  <BasicTextInput
                    key={`input-${name}`}
                    name={`input-${name}`}
                    typeNumber
                    onChange={(val) => handleChange(null, val)}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={localValue}
                    placeholder=""
                    marginTop={0}
                    centerText={r({
                      xs: type === 'money' ? false : true,
                      sm: type === 'money' ? false : true,
                      md: type === 'money' ? false : true
                    })}
                    disabled={disabled}
                    moneyAdornment={type === 'money'}
                  />
                ) : (
                  <Box className={classes.coverLabel}>
                    <Typography variant="body2" color="textPrimary">
                      <Box component="span" fontWeight="600">
                        {convertToINR(localValue, 2)}
                      </Box>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.seekerRoot}>
          <div className={classes.seeker}>
            <InsuranceSlider
              classes={{
                mark: type === 'money' ? classes.moneyMark : classes.ageMark
              }}
              value={localValue}
              marks={markers}
              valueLabelFormat={
                type === 'money' ? valueLabelFormatted : valueLabelFormattedAge
              }
              //ValueLabelComponent={ValueLabelComponent}
              valueLabelDisplay="on"
              defaultValue={min}
              step={step}
              max={max}
              min={min}
              onChange={handleChange}
              onChangeCommitted={() => {
                'onDragover';
              }}
              disabled={disabled}
              //ThumbComponent={DittoThumbComponent}
            />
          </div>
          <div className={classes.seekerLabelsContainer}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              classes={{ root: classes.minLabel }}
            >
              {type === 'money' ? convertToINR(min, 2) : min}
            </Typography>

            <Typography
              variant="subtitle2"
              color="textSecondary"
              classes={{ root: classes.maxLabel }}
            >
              {type === 'money' ? convertToINR(max, 2) : max}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
);

Seeker.defaultProps = {
  type: 'money'
};

export { Seeker };
