import React from 'react';
import { Container, Box } from '@material-ui/core';

import { Header } from '../components';
import { useStyles } from '../theme/styles';
import config from '../config';

interface AppLayoutProps {
  headerTitle?: string;
  headerLeft?: any;
}

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const classes = useStyles();
  return (
    <>
      <Header headerTitle={props.headerTitle} headerLeft={props.headerLeft} />
      <div className={classes.toolbar} />
      <Container classes={{ root: classes.containerRoot }} maxWidth={false}>
        {props.children ? props.children : ''}
        <Box
          position="absolute"
          top={0}
          right={0}
          width="100%"
          textAlign="right"
          padding={1}
          color="#e0e0e0"
        >
          {`v${config.APP_VERSION}`}
        </Box>
      </Container>
    </>
  );
};

export { AppLayout };
