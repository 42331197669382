import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    fontSize: 16
  },
  input: {
    padding: theme.spacing(0)
  },
  inputRoot: {
    background: theme.palette.common.white,
    '&.MuiOutlinedInput-root': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '12px',
        paddingBottom: '12px'
      }
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      background: '#F7F9FA !important',
      color: '#BFC4C6 !important'
    }
  },

  autoCompleteRoot: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(3)
  },
  autoCompleteFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important',
    padding: theme.spacing(0)
  }
}));

interface AutoCompleteOption {
  value: string | number;
  label: string;
  customOption?: boolean | undefined;
}

interface AutocompleteProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: AutoCompleteOption[];
  helperText?: string;
  includeDynamicOption?: boolean;
  errorText?: string;
  touched?: boolean;
  disabled?: boolean;
  value: any;
  marginTop?: number;
  onChange: (arg1: string) => void;
  onBlur?: (arg1: any) => void;
  afterCustomSelection?: (value: string) => any;
  afterSelection?: (value: string) => any;
}

const filter = createFilterOptions<AutoCompleteOption>();

const BasicAutoComplete: React.FC<AutocompleteProps> = React.memo(
  ({
    name,
    options,
    label,
    placeholder,
    helperText,
    includeDynamicOption,
    errorText,
    touched,
    value,
    disabled,
    marginTop,
    onChange,
    onBlur,
    afterCustomSelection,
    afterSelection
  }: AutocompleteProps) => {
    const classes = useStyles();
    const [focussed, setFocussed] = useState<boolean>(false);

    const currentValue = options.find((o) => o.value === value);

    const handleOnChange = (event: any, value: any, reason: string) => {
      if (value && includeDynamicOption && value.customOption) {
        onChange(value.value);
        afterCustomSelection && afterCustomSelection(value.value);
      } else if (value) {
        onChange(value.value);
        afterSelection && afterSelection(value.value);
      } else {
        onChange('');
      }
    };

    return (
      <MuiAutocomplete
        id={`auto-complete-${name}`}
        options={options}
        classes={{
          root: clsx(
            classes.autoCompleteRoot,
            currentValue && !errorText && !focussed
              ? classes.autoCompleteFilled
              : null
          ),
          inputRoot: classes.inputRoot,
          input: !disabled ? classes.input : classes.textInputDisabled,
          option: classes.option
        }}
        style={marginTop ? { marginTop } : undefined}
        autoHighlight
        fullWidth
        getOptionLabel={(option: AutoCompleteOption) => option.label}
        value={currentValue ? currentValue : null}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreIcon />}
        {...(includeDynamicOption
          ? {
              filterOptions: (options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    value: params.inputValue,
                    label: params.inputValue,
                    customOption: true
                  });
                }
                return filtered;
              },
              freeSolo: true
            }
          : {})}
        renderInput={(params) => {
          return (
            <>
              {label ? (
                <InputLabel {...params.InputLabelProps}>{label}</InputLabel>
              ) : null}
              <TextField
                placeholder={placeholder ? placeholder : label}
                onFocus={() => {
                  setFocussed(true);
                }}
                error={errorText && touched ? true : false}
                helperText={
                  errorText && touched
                    ? errorText
                    : helperText
                    ? helperText
                    : ''
                }
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off' // disable autocomplete and autofill
                }}
                onBlur={(event) => {
                  setFocussed(false);
                  onBlur && onBlur(event);
                }}
              />
            </>
          );
        }}
      />
    );
  }
);

export { BasicAutoComplete };
