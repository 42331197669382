import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import { useField } from 'formik';

import { useResponsive } from '../../theme/styles';
import { ValidationIcon } from './ValidationIcon';

import { ButtonSwitcherOptions } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(3)
  },
  switcherButtonRoot: {
    height: 44,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '12px',
      paddingBottom: '12px'
    },
    '&.Mui-disabled': {
      pointerEvents: 'all'
    }
  },
  switcherButtonLabel: {
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  switcherButtonLabelActive: {
    color: theme.palette.primary.main
  },
  switcherButtonLabelDisabled: {
    color: '#BFC4C6'
  },
  switcherButtonDisabled: {
    background: '#F7F9FA !important',
    cursor: 'not-allowed !important',
    color: '#BFC4C6 !important',
    borderColor: '#E8EAEB !important',
    pointerEvents: 'all'
  },
  switcherButtonRootDisabledSelected: {
    borderColor: `${theme.palette.secondary.main} !important`
  },
  hasRightLabel: {
    justifyContent: 'space-between !important'
  },
  labelRight: {
    fontSize: 14,
    fontWeight: 400,
    color: '#666666',
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(3)
  },

  labelLeftAlign: {
    justifyContent: 'flex-start'
  },
  labelRightWithTick: {
    marginRight: theme.spacing(1 / 2)
  },
  nbp: {
    paddingBottom: '0px !important'
  },
  fixHeight: {
    height: 44
  }
}));

const styles = {
  active: {
    background: '#F7FBFE',
    border: '2px solid #3DABF5'
  },
  inactive: {
    background: 'white'
  }
};

interface ButtonSwitcherProps {
  name: string;
  options: [ButtonSwitcherOptions, ButtonSwitcherOptions];
  label: string;
  disabled?: boolean;
  showTick?: boolean;
  showRightLabel?: boolean;
  leftAlignLabel?: boolean;
  buttonGridSize?: 3 | 4 | 5 | 6;
}

const ButtonSwitcher: React.FC<ButtonSwitcherProps> = React.memo(
  (props: ButtonSwitcherProps) => {
    const {
      name,
      options,
      label,
      disabled,
      showTick,
      showRightLabel,
      leftAlignLabel,
      buttonGridSize
    } = props;

    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const r = useResponsive();

    return (
      <div className={classes.textFieldContainer}>
        <Box marginBottom={1}>
          <InputLabel>{label}</InputLabel>
        </Box>
        <Grid container className={classes.fixHeight}>
          <Grid container item spacing={r({ xs: 1, sm: 2, md: 2 })}>
            {options.map((option, index) => {
              const selected = option.value === field.value;
              return (
                <Grid
                  key={index}
                  className={classes.nbp}
                  item
                  xs={6}
                  sm={6}
                  md={buttonGridSize ? buttonGridSize : 4}
                >
                  <Button
                    disabled={disabled}
                    classes={{
                      root: clsx(classes.switcherButtonRoot),
                      label: clsx(
                        !disabled
                          ? classes.switcherButtonLabel
                          : classes.switcherButtonLabelDisabled,
                        selected ? classes.switcherButtonLabelActive : null,
                        showRightLabel || showTick ? classes.hasRightLabel : null,
                        leftAlignLabel ? classes.labelLeftAlign : null
                      ),
                      disabled: clsx(
                        classes.switcherButtonDisabled,
                        disabled && selected
                          ? classes.switcherButtonRootDisabledSelected
                          : null
                      )
                    }}
                    style={!selected ? styles.inactive : styles.active}
                    size="large"
                    fullWidth
                    variant={!selected ? 'outlined' : 'contained'}
                    onClick={() => {
                      helpers.setValue(option.value);
                      helpers.setTouched(true, false);
                    }}
                    color={!selected ? 'default' : 'primary'}
                    disableElevation
                  >
                    {option.label}
                    {option.labelRight && showRightLabel && (
                      <Box
                        component="span"
                        className={clsx(
                          classes.labelRight,
                          showTick && selected ? classes.labelRightWithTick : null
                        )}
                      >
                        {option.labelRight}
                        {showTick && selected && (
                          <Box
                            component="span"
                            display="flex"
                            height={24}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                          </Box>
                        )}
                      </Box>
                    )}
                    {!option.labelRight && showTick && selected && (
                      <Box
                        component="span"
                        display="flex"
                        height={24}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                      </Box>
                    )}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {meta.error && meta.touched && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { ButtonSwitcher };
