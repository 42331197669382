import React from 'react';
import { Box, CircularProgress, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import DittoMascot from '../../res/img/dittoiconsmall.png';
import { Button } from '../buttons/Button';

const useStyles = makeStyles((theme: Theme) => ({
  fieldInfoImg: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
    marginRight: theme.spacing(0.7)
  },
  fieldInfoImgNoMargin: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8)
  },
  infoLoading: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

interface InfoProps {
  dittoIcon?: boolean;
  dittoIconBig?: boolean;
  info: string;
  type: 'info' | 'pageinfo';
  cta?: string;
  loading?: boolean;
  xsOnly?: boolean;
  onClickCta?: () => void;
}

const Info: React.FC<InfoProps> = React.memo(
  ({
    dittoIcon,
    dittoIconBig,
    type,
    info,
    cta,
    xsOnly,
    loading,
    onClickCta
  }) => {
    const classes = useStyles();
    const iconBigProps = dittoIconBig
      ? {
          width: 41,
          height: 41,
          borderRadius: '50%',
          boxShadow: '0px 0.5px 6px 1px rgba(0, 0, 0, 0.04)',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 1.2,
          bgcolor: 'white'
        }
      : {};
    const infoComponent = (
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        bgcolor={type === 'info' ? '#F7F9FA' : 'rgba(30, 201, 255,0.10)'}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={8}
        padding={1.1}
        marginY={1.2}
        className={loading ? classes.infoLoading : undefined}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {dittoIcon ? (
            <Box display="flex" flexShrink={0} {...iconBigProps}>
              <img
                src={DittoMascot}
                className={
                  !dittoIconBig
                    ? classes.fieldInfoImg
                    : classes.fieldInfoImgNoMargin
                }
                alt="ditto"
              />
            </Box>
          ) : (
            <Box marginRight={0.8} marginTop={0.2}>
              <ErrorOutlineIcon style={{ color: '#3DABF5', fontSize: 16 }} />
            </Box>
          )}
          <Hidden smUp>
            <Typography variant="caption" color="textPrimary">
              {info}
            </Typography>
          </Hidden>
          <Hidden xsDown>
            <Typography variant="body2" color="textPrimary">
              {info}
            </Typography>
          </Hidden>
          {loading && (
            <CircularProgress
              color="primary"
              size={20}
              style={{ marginLeft: 10, marginRight: 10 }}
            />
          )}
        </Box>

        {cta && onClickCta && (
          <Box
            display="flex"
            flexShrink={0}
            alignSelf="stretch"
            flexDirection="column"
          >
            <Button
              variant="text"
              color="primary"
              onClick={onClickCta}
              disableElevation
              size="small"
            >
              {cta}
            </Button>
          </Box>
        )}
      </Box>
    );
    if (xsOnly) {
      return <Hidden smUp>{infoComponent}</Hidden>;
    }
    return infoComponent;
  }
);

export { Info };
