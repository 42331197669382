import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

export const FormContainer: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box width="100%" {...rest}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>{children}</Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
