import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

import { useField } from 'formik';

const useStyles = makeStyles((theme: Theme) => ({
  switcherButtonRoot: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',
    border: '1px solid #D9DDDE',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.up('md')]: {
      height: 54,
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  switcherButtonRootSmall: {
    [theme.breakpoints.up('md')]: {
      height: '44px !important'
    }
  },
  switcherButtonLabelSmall: {
    [theme.breakpoints.up('md')]: {
      fontSize: `14px !important`
    }
  },
  switcherButtonLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    },
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.common.black,
    textAlign: 'left'
  },
  switcherButtonRootCol2: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  hasRightLabel: {
    justifyContent: 'space-between !important'
  },
  labelRight: {
    fontSize: 14,
    fontWeight: 400,
    color: '#666666',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelLeftAlign: {
    justifyContent: 'flex-start'
  },
  labelRightWithTick: {
    marginRight: theme.spacing(1 / 2)
  }
}));

const styles = {
  active: {
    background: '#F7FBFE',
    border: '2px solid #3DABF5'
  },
  inactive: {
    background: 'white'
  }
};

interface Option {
  label: string;
  labelRight?: string;
}

interface MultiLabelCheckBoxProps {
  name: string;
  option: Option;
  isChecked?: boolean;
  label?: string;
  columns?: 1 | 2;
  showTick?: boolean;
  showRightLabel?: boolean;
  leftAlignLabel?: boolean;
  onChange?: (value: boolean) => void;
  small?: boolean;
}

const MultiLabelCheckBox: React.FC<MultiLabelCheckBoxProps> = React.memo(
  ({
    name,
    option,
    isChecked,
    showRightLabel,
    showTick,
    leftAlignLabel,
    small,
    onChange
  }: MultiLabelCheckBoxProps) => {
    const [field, meta, helpers] = useField(name);
    const classes = useStyles();
    const handleChange = () => {
      onChange ? onChange(!field.value) : helpers.setValue(!field.value);
    };

    isChecked = isChecked ? isChecked : field.value;

    return (
      <Grid item md={6} sm={6} xs={12}>
        <Button
          classes={{
            root: clsx(
              classes.switcherButtonRoot,
              classes.switcherButtonRootCol2,
              small ? classes.switcherButtonRootSmall : null
            ),
            label: clsx(
              classes.switcherButtonLabel,
              showRightLabel || showTick ? classes.hasRightLabel : null,
              leftAlignLabel ? classes.labelLeftAlign : null,
              small ? classes.switcherButtonLabelSmall : null
            )
          }}
          style={!isChecked ? styles.inactive : styles.active}
          size="large"
          fullWidth
          variant={!isChecked ? 'outlined' : 'contained'}
          onClick={(e) => handleChange()}
          color={!isChecked ? 'default' : 'primary'}
          disableElevation
        >
          {option.label}
          {option.labelRight && showRightLabel && (
            <Box component="span" className={clsx(classes.labelRight)}>
              {showTick && isChecked && (
                <Box
                  component="span"
                  display="flex"
                  height={24}
                  width={30}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                </Box>
              )}
              {option.labelRight}
            </Box>
          )}
          {!option.labelRight && showTick && isChecked && (
            <Box
              component="span"
              display="flex"
              height={24}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
            </Box>
          )}
        </Button>
      </Grid>
    );
  }
);

export { MultiLabelCheckBox };
