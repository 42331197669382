import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ValidationIcon } from '../ValidationIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '4px',
    color: 'transparent',
    background: theme.palette.common.white,
    borderRadius: '4px',
    border: `2px solid ${theme.palette.grey[500]}`
  },
  rootSelected: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: '4px',
    border: `none`,
    padding: '1px'
  },
  rootSelectedSuccess: {
    padding: '4px',
    color: '#999999',
    background: '#0BF940',
    borderRadius: '4px'
  },
  fontSizeSmall: {
    fontSize: '16px'
  },
  fontSizeSmallSuccess: {
    fontSize: '24px',
    color: theme.palette.common.white,
    fontWeight: 500
  },
  formControlLabelRoot: {
    marginRight: 0,
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      padding: '4px 12px'
    }
  },
  labelRoot: {
    marginRight: 0,
    marginLeft: -9
  },
  label: {
    fontSize: theme.spacing(1.4),
    color: theme.palette.grey[700],
    fontWeight: 400
  },
  rootDisabled: {
    background: theme.palette.grey[300]
  },
  labelBold: {
    fontWeight: 500
  }
}));

interface CheckboxProps {
  name: string;
  label?: string;
  boldLabel?: boolean;
  checked?: boolean;
  success?: boolean;
  onChange: (checked: boolean) => void;
  error?: string;
  disabled?: boolean;
}

const BasicCheckbox: React.FC<CheckboxProps> = ({
  name,
  label,
  boldLabel,
  checked,
  success,
  error,
  disabled,
  onChange
}) => {
  const classes = useStyles();

  return (
    <div>
      <FormControlLabel
        control={
          <MuiCheckbox
            color="primary"
            icon={
              <CheckBoxOutlineBlankIcon
                fontSize="small"
                classes={{
                  root: clsx(
                    classes.root,
                    disabled ? classes.rootDisabled : undefined
                  ),
                  fontSizeSmall: classes.fontSizeSmall,
                  colorDisabled: '#dadada'
                }}
              />
            }
            checkedIcon={
              <CheckIcon
                fontSize="small"
                classes={{
                  root: clsx(
                    !success
                      ? classes.rootSelected
                      : classes.rootSelectedSuccess,
                    disabled ? classes.rootDisabled : undefined
                  ),
                  fontSizeSmall: !success
                    ? classes.fontSizeSmall
                    : classes.fontSizeSmallSuccess,
                  colorDisabled: '#dadada'
                }}
              />
            }
            name={name}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            disabled={disabled}
          />
        }
        label={label ? label : ''}
        classes={{
          label: clsx(classes.label, boldLabel ? classes.labelBold : null),
          root: classes.labelRoot
        }}
      />
      {error && error !== '' && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <ValidationIcon />
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </div>
  );
};

export { BasicCheckbox };
