import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { AppLayout } from '../layout';
import { Box, Grid } from '@material-ui/core';
import { Button, Info } from '../components';
import { Alert } from '@material-ui/lab';
import config, { APP_STAGE_DEVELOPMENT, APP_STAGE_STAGING } from '../config';

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

export default class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // Log the error to Sentry
    if (
      config.APP_STAGE === APP_STAGE_DEVELOPMENT ||
      config.APP_STAGE === APP_STAGE_STAGING
    ) {
      //don't remove
      console.log({ error, errorInfo });
    }
    // prevent reporting chunk load error
    if (error.name !== 'ChunkLoadError') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }
  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <AppLayout>
          <Grid container justify="center" direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <Box
                  maxWidth={450}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {error.name === 'ChunkLoadError' ? (
                    <>
                      <Info
                        type="info"
                        info="This application has been updated, please refresh your browser
                  to see the latest content."
                        dittoIcon
                        dittoIconBig
                      />
                      <Box width={250}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          fullWidth
                          onClick={() => window.location.reload()}
                        >
                          Update
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Alert severity="error">
                        An error has occurred, please refresh and try again.
                      </Alert>
                      <Box width={250} marginTop={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          fullWidth
                          onClick={() => window.location.reload()}
                        >
                          Refresh
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AppLayout>
      );
    }
    return this.props.children;
  }
}
