import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useResponsive } from '../../theme/styles';
import { drawerWidth, drawerWidthMobile } from '../../theme/constants';
import {
  Dashboard,
  Link as LinkIcon,
  Group,
  AccountCircle,
  ExitToApp
} from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router';
import {
  ROUTE_DASHBOARD,
  ROUTE_LEADS,
  ROUTE_PROFILE,
  ROUTE_REFERRAL_LINKS
} from '../../routes/constants';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        width: drawerWidthMobile,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
      }
    },
    drawerPaper: {
      width: drawerWidth,
      background: 'white',
      borderLeft: 'none',
      [theme.breakpoints.down('sm')]: {
        width: drawerWidthMobile
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 66,
        height: 'calc(100vh - 66px)'
      },
      borderRight: 'none !important'
    },
    icon: {
      fontSize: 24,
      color: '#616262',
      marginRight: 20
    },
    iconSelected: {
      color: '#3DABF5'
    }
  })
);

interface AppDrawerProps {
  isOpen: boolean;
  handleDrawerClose: VoidFunction;
}

interface MenuItemProps {
  title: string;
  link?: string;
  onClick?: VoidFunction;
  handleDrawerClose: VoidFunction;
}

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  link,
  handleDrawerClose,
  onClick
}) => {
  const location = useLocation();

  let selected = link ? location.pathname.includes(link) : false;

  const classes = useStyles();
  let icon = null;
  if (link) {
    switch (link) {
      case ROUTE_DASHBOARD:
        selected = selected || location.pathname === '/';
        icon = (
          <Dashboard
            className={clsx(classes.icon, selected ? classes.iconSelected : null)}
          />
        );
        break;
      case ROUTE_REFERRAL_LINKS:
        icon = (
          <LinkIcon
            className={clsx(classes.icon, selected ? classes.iconSelected : null)}
          />
        );
        break;
      case ROUTE_LEADS:
        icon = (
          <Group
            className={clsx(classes.icon, selected ? classes.iconSelected : null)}
          />
        );
        break;
      case ROUTE_PROFILE:
        icon = (
          <AccountCircle
            className={clsx(classes.icon, selected ? classes.iconSelected : null)}
          />
        );
        break;
    }
  } else {
    switch (title) {
      case 'Sign out':
        icon = (
          <ExitToApp
            className={clsx(classes.icon, selected ? classes.iconSelected : null)}
          />
        );
    }
  }

  return link ? (
    <Link
      to={`/${link}`}
      style={{ textDecoration: 'none', width: '100%' }}
      onClick={handleDrawerClose}
    >
      <Box
        display="flex"
        flexDirection="row"
        borderRight={selected ? '4px solid #3DABF5' : '4px solid white'}
        bgcolor={selected ? 'rgba(61, 171, 245, 0.06)' : 'white'}
        width="100%"
        paddingY={1.6}
        paddingLeft={5.6}
        color={selected ? '#3DABF5' : '#616262'}
      >
        {icon}
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
    </Link>
  ) : (
    <Box
      display="flex"
      flexDirection="row"
      borderRight={selected ? '4px solid #3DABF5' : '4px solid white'}
      bgcolor={selected ? 'rgba(61, 171, 245, 0.06)' : 'white'}
      width="100%"
      paddingY={1.6}
      paddingLeft={5.6}
      color={selected ? '#3DABF5' : '#616262'}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        handleDrawerClose();
        onClick && onClick();
      }}
    >
      {icon}
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  );
};

export const AppDrawer: React.FC<AppDrawerProps> = ({
  isOpen,
  handleDrawerClose
}) => {
  const r = useResponsive();
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <Drawer
      open={isOpen}
      onClose={handleDrawerClose}
      className={classes.drawer}
      variant={r({
        lg: 'permanent',
        md: 'permanent',
        sm: 'temporary',
        xs: 'temporary'
      })}
      classes={{
        paper: classes.drawerPaper
      }}
      anchor={'left'}
    >
      <Box
        height={r({ md: 'calc(100vh - 64px)', xs: 'auto' })}
        marginTop={r({ md: 6.4, xs: 3 })}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <MenuItem
          title="Dashboard"
          link="dashboard"
          handleDrawerClose={handleDrawerClose}
        />
        {/* <MenuItem
          title="Referral Links"
          link="referral"
          handleDrawerClose={handleDrawerClose}
        /> */}
        <MenuItem
          title="Leads & converts"
          link="leads"
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItem
          title="Profile"
          link="profile"
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItem
          title="Sign out"
          onClick={() =>
            auth.signout(() => {
              navigate('/');
            })
          }
          handleDrawerClose={handleDrawerClose}
        />
      </Box>
    </Drawer>
  );
};
