import React, { ReactElement } from 'react';
import { AppBar, Toolbar, Hidden, Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    background: 'white',
    boxShadow: '0px 0px 25px rgba(119, 119, 119, 0.15)'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: {
      minHeight: 80
    }
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

interface BottomBarMobileProps {
  left?: ReactElement;
  right?: ReactElement;
}

const BottomBarMobile: React.FC<BottomBarMobileProps> = React.memo(
  ({ left, right, children }) => {
    const classes = useStyles();
    return (
      <>
        <div className={classes.toolbar} />
        <Hidden mdUp>
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar classes={{ root: classes.toolbarRoot }}>
              {left || right ? (
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={6}>
                    {left}
                  </Grid>
                  <Grid item xs={6}>
                    {right}
                  </Grid>
                </Grid>
              ) : (
                children
              )}
            </Toolbar>
          </AppBar>
        </Hidden>
      </>
    );
  }
);

export { BottomBarMobile };
