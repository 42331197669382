import { useAuth } from '../context/AuthProvider';
import { useLocation, Navigate } from 'react-router-dom';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export const NoAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  if (auth.user) {
    // Redirect them to the / page, if already logged in
    return <Navigate to="/" />;
  }

  return children;
};
