import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    column3: {
      order: 3,
      [theme.breakpoints.up('sm')]: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        order: 2
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(0)}px !important`,
        paddingBottom: `${theme.spacing(3)}px !important`
      }
    },
    column3withInfo: {
      order: 3,
      [theme.breakpoints.up('sm')]: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        order: 2
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(3)}px !important`,
        paddingBottom: `${theme.spacing(3)}px !important`
      }
    },
    column2: {
      order: 2,
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      [theme.breakpoints.up('sm')]: {
        order: 3
      }
    },
    column1: {
      [theme.breakpoints.up('md')]: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(3)}px !important`,
        paddingBottom: `${theme.spacing(3)}px !important`
      }
    }
  })
);

interface ContentLayoutProps {
  left?: ReactElement;
  right?: ReactElement | null;
  contentColumns?: 7 | 8 | 11;
  hasInfoBox?: boolean;
}

const ContentLayout: React.FC<ContentLayoutProps> = React.memo(
  ({ left, right, contentColumns = 7, hasInfoBox, children }) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Grid container item spacing={2}>
          {left ? (
            <Hidden smDown>
              <Grid item md={1} className={classes.column1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  {left}
                </Box>
              </Grid>
            </Hidden>
          ) : null}
          <Grid
            item
            md={contentColumns}
            sm={contentColumns === 8 ? 7 : contentColumns}
            xs={12}
            className={!hasInfoBox ? classes.column3 : classes.column3withInfo}
          >
            {children}
          </Grid>
          {(contentColumns === 7 || contentColumns === 8) && (
            <Grid item md={4} sm={5} xs={12} className={classes.column2}>
              {right ? right : null}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
);

export { ContentLayout };
