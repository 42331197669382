import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import { FormikErrors } from 'formik';

import { ValidationIcon } from '../ValidationIcon';

import { FormRow } from '../../spacing';
import { useResponsive } from '../../../theme/styles';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  label: {
    fontWeight: 500
  },
  switcherButtonRoot: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',
    border: '1px solid #D9DDDE',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.up('md')]: {
      height: 54,
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  switcherButtonRootSmall: {
    [theme.breakpoints.up('md')]: {
      height: '44px !important'
    }
  },
  switcherButtonLabelSmall: {
    [theme.breakpoints.up('md')]: {
      fontSize: `14px !important`
    }
  },
  switcherButtonLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    },
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textAlign: 'left'
  },
  switcherButtonLabelSelected: {
    color: theme.palette.primary.main
  },
  switcherButtonLabelBold: {
    color: theme.palette.common.black
  },
  switcherButtonRootCol2: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  hasRightLabel: {
    justifyContent: 'space-between !important'
  },
  labelRight: {
    fontSize: 14,
    fontWeight: 400,
    color: '#666666',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelLeftAlign: {
    justifyContent: 'flex-start'
  },
  labelRightWithTick: {
    marginRight: theme.spacing(1 / 2)
  }
}));

const styles = {
  active: {
    background: '#F7FBFE',
    border: '2px solid #3DABF5'
  },
  inactive: {
    background: 'white'
  }
};

interface Option {
  label: string;
  value: string | number;
  labelRight?: string;
}

interface BasicMultiSelectButtonsProps {
  name: string;
  options: Option[];
  label: string;
  columns?: 1 | 2;
  useColumnsInXs?: boolean;
  showTick?: boolean;
  showRightLabel?: boolean;
  leftAlignLabel?: boolean;
  value: any;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  bg?: string;
  disabled?: boolean;
  small?: boolean;
  marginBottom?: number;
  onChange?: (value: string[]) => void;
  fontBold?: boolean;
}

const BasicMultiSelectButtons: React.FC<BasicMultiSelectButtonsProps> = React.memo(
  ({
    name,
    options,
    label,
    columns,
    useColumnsInXs,
    showRightLabel,
    showTick,
    leftAlignLabel,
    value,
    error,
    disabled,
    small,
    marginBottom,
    onChange,
    fontBold
  }: BasicMultiSelectButtonsProps) => {
    const classes = useStyles();
    const r = useResponsive();

    const renderValueOption = (option: Option, index: number) => {
      const isSelected = value.length ? value.includes(option.value) : false;
      return (
        <Grid
          key={index}
          item
          md={columns === 2 ? 6 : 12}
          sm={columns === 2 ? 6 : 12}
          xs={!useColumnsInXs ? 12 : columns === 2 ? 6 : 12}
        >
          <Button
            classes={{
              root: clsx(
                classes.switcherButtonRoot,
                columns === 2 ? classes.switcherButtonRootCol2 : null,
                small ? classes.switcherButtonRootSmall : null
              ),
              label: clsx(
                classes.switcherButtonLabel,
                isSelected ? classes.switcherButtonLabelSelected : null,
                fontBold
                  ? classes.switcherButtonLabelBold
                  : classes.switcherButtonLabel,
                showRightLabel || showTick ? classes.hasRightLabel : null,
                leftAlignLabel ? classes.labelLeftAlign : null,
                small ? classes.switcherButtonLabelSmall : null
              )
            }}
            style={!isSelected ? styles.inactive : styles.active}
            size="large"
            fullWidth
            variant={!isSelected ? 'outlined' : 'contained'}
            onClick={() => {
              if (!value.includes(option.value)) {
                onChange && onChange([...value, option.value]);
              } else {
                onChange &&
                  onChange(value.filter((e: any) => e !== option.value));
              }
            }}
            color={!isSelected ? 'default' : 'primary'}
            disableElevation
            disabled={disabled}
          >
            {option.label}
            {option.labelRight && showRightLabel && (
              <Box component="span" className={clsx(classes.labelRight)}>
                {showTick && isSelected && (
                  <Box
                    component="span"
                    display="flex"
                    height={24}
                    width={30}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                  </Box>
                )}
                {option.labelRight}
              </Box>
            )}
            {!option.labelRight && showTick && isSelected && (
              <Box
                component="span"
                display="flex"
                height={24}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
              </Box>
            )}
          </Button>
        </Grid>
      );
    };

    const renderValueOptionBase = (option: Option, index: number) => {
      const isSelected =
        value && Object.keys(value).length
          ? value[option.value] === true
          : false;
      return (
        <Grid
          key={`${name + '-' + index}-multi-select-option`}
          item
          md={columns === 2 ? 6 : 12}
          sm={columns === 2 ? 6 : 12}
          xs={!useColumnsInXs ? 12 : columns === 2 ? 6 : 12}
        >
          <Button
            classes={{
              root: clsx(
                classes.switcherButtonRoot,
                columns === 2 ? classes.switcherButtonRootCol2 : null
              ),
              label: clsx(
                classes.switcherButtonLabel,
                isSelected ? classes.switcherButtonLabelSelected : null,
                showRightLabel || showTick ? classes.hasRightLabel : null,
                leftAlignLabel ? classes.labelLeftAlign : null
              )
            }}
            style={!isSelected ? styles.inactive : styles.active}
            size="large"
            fullWidth
            variant={!isSelected ? 'outlined' : 'contained'}
            onClick={() => {
              if (
                value &&
                (value[option.value] === '' ||
                  value[option.value] === undefined ||
                  value[option.value] === false)
              ) {
                onChange && onChange({ ...value, [option.value]: true });
              } else {
                onChange &&
                  onChange({
                    ...value,
                    [option.value]: false
                  });
              }
            }}
            color={!isSelected ? 'default' : 'primary'}
            disableElevation
          >
            {option.label}
            {option.labelRight && showRightLabel && (
              <Box component="span" className={clsx(classes.labelRight)}>
                {showTick && isSelected && (
                  <Box
                    component="span"
                    display="flex"
                    height={24}
                    width={30}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
                  </Box>
                )}
                {option.labelRight}
              </Box>
            )}
            {!option.labelRight && showTick && isSelected && (
              <Box
                component="span"
                display="flex"
                height={24}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CheckIcon htmlColor="#1FC9FF" fontSize="small" />
              </Box>
            )}
          </Button>
        </Grid>
      );
    };

    const renderRowOfTwo = (index: number) => {
      return (
        <FormRow key={index}>
          {Array.isArray(value)
            ? renderValueOption(options[index - 1], index - 1)
            : renderValueOptionBase(options[index - 1], index - 1)}
          {Array.isArray(value)
            ? renderValueOption(options[index], index)
            : renderValueOptionBase(options[index], index)}
        </FormRow>
      );
    };

    const renderRowOfOne = (index: number) => {
      return (
        <FormRow key={index}>
          {Array.isArray(value)
            ? renderValueOption(options[index], index)
            : renderValueOptionBase(options[index], index)}
        </FormRow>
      );
    };

    return (
      <div
        className={classes.textFieldContainer}
        style={marginBottom ? { marginBottom } : undefined}
      >
        <Box marginBottom={1}>
          <Box marginBottom={2.5}>
            <Typography
              variant="body2"
              color="textSecondary"
              classes={{ root: classes.label }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          spacing={!columns || columns === 1 ? 1 : r({ md: 2, sm: 2, xs: 0 })}
        >
          {options.map((option: Option, index: number) => {
            if (columns === 2) {
              if (index % 2 === 1) {
                return renderRowOfTwo(index);
              } else if (index % 2 === 0 && index === options.length - 1) {
                return renderRowOfOne(index);
              }
            } else {
              return renderRowOfOne(index);
            }
          })}
        </Grid>
        {error && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { BasicMultiSelectButtons };
