import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from 'formik';
import clsx from 'clsx';
import { ValidationIcon } from './ValidationIcon';

const useStyles = makeStyles((theme: Theme) => ({
  selectFieldContainer: {
    marginTop: theme.spacing(3)
  },
  selectContainer: {
    width: '100%',
    flex: 1
  },
  selectInputRoot: {
    background: 'white',
    width: '100%',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  selectInputRootDisabled: (props) => {
    return {
      background: 'white',
      width: '100%',
      color: 'gray',
      '&:focus': {
        backgroundColor: 'white'
      }
      // '&:before': {
      //   //@ts-ignore
      //   content: `"${props.placeholder}"`,
      //   position: 'absolute',
      //   top: 12
      // }
    };
  },
  selectLabel: {
    marginBottom: theme.spacing(1)
  },
  optionsTextTransform: {
    textTransform: 'capitalize'
  },
  textInputDisabled: {
    cursor: 'not-allowed !important',
    background: '#F7F9FA !important',
    color: '#BFC4C6 !important'
  },
  selectBoxFilled: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #9CA3A5'
    }
  },
  selectPaper: {
    boxShadow: 'none',
    border: '1px solid #E8EAEB',
    marginTop: 4,
    '& .MuiMenu-list': {
      paddingTop: 0
    }
  },
  noneOptionRoot: {
    fontSize: '12px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'white !important',
    color: 'black',
    display: 'none'
  }
}));

interface SelectBoxProps {
  options: any;
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  defaultselected?: string;
}

const SelectBox: React.FC<SelectBoxProps> = React.memo(
  (props: SelectBoxProps) => {
    const {
      options,
      name,
      label,
      placeholder,
      disabled,
      defaultselected
    } = props;

    const [field, meta, helpers] = useField(name);

    const classes = useStyles({ placeholder });

    const [focussed, setFocussed] = useState<boolean>();
    const [selected, setSelected] = useState<any>(
      field.value ? field.value : 'none'
    );

    // Needs review
    useEffect(() => {
      if (field.value === selected) return;
      setSelected(field.value ? field.value : 'none');
    }, [field.value]);

    const handleChange = (
      event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
      if (event.target.value !== 'none') {
        setSelected(event.target.value as string);
        helpers.setValue(event.target.value);
      }
    };

    return (
      <div
        className={clsx(
          classes.selectFieldContainer,
          selected !== 'none' && !meta.error && !focussed
            ? classes.selectBoxFilled
            : undefined
        )}
      >
        <InputLabel classes={{ root: classes.selectLabel }}>{label}</InputLabel>
        <Select
          displayEmpty
          disabled={disabled}
          id={`select-${field.name}`}
          name={field.name}
          fullWidth
          value={defaultselected ? defaultselected : selected}
          onChange={handleChange}
          variant="outlined"
          classes={{
            root:
              selected !== 'none'
                ? classes.selectInputRoot
                : classes.selectInputRootDisabled,
            disabled: classes.textInputDisabled
          }}
          error={meta.error && meta.touched ? true : false}
          onFocus={() => setFocussed(true)}
          onBlur={(e) => {
            setFocussed(false);
            field.onBlur(e);
          }}
          IconComponent={ExpandMoreIcon}
          inputProps={{
            autoComplete: 'nope'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            elevation: 0,
            classes: {
              paper: classes.selectPaper
            },
            disableScrollLock: true
          }}
        >
          <MenuItem value="none" classes={{ root: classes.noneOptionRoot }}>
            {placeholder}
          </MenuItem>
          {options &&
            options.map((option: any, index: number) => {
              return (
                <MenuItem
                  className={classes.optionsTextTransform}
                  key={index}
                  value={typeof option === 'object' ? option.value : option}
                  autoFocus={
                    !field.value && field.value !== 'none' ? index === 0 : false
                  }
                >
                  {typeof option === 'object'
                    ? option.label
                      ? option.label
                      : option.value
                    : option}
                </MenuItem>
              );
            })}
        </Select>
        {meta.error && meta.touched && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ValidationIcon />
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </div>
    );
  }
);

export { SelectBox };
