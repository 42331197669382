import React from 'react';
import { Paper, Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DittoMascot from '../../res/img/logoicon.png';
import { FieldInfoMobile } from './FieldInfoMobile';

const useStyles = makeStyles((theme: Theme) => ({
  filedInfoContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center'
  },
  fieldInfoRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    },
    width: '100%',
    //marginLeft: theme.spacing(5),
    height: 'wrap-content'
  },
  fieldInfoCircle: {
    color: theme.palette.getContrastText('#FFF96D'),
    backgroundColor: '#FFF96D',
    height: '64px',
    width: '64px',
    position: 'absolute',
    left: 116,
    top: -34
  },
  fieldInfoImg: {
    position: 'absolute',
    top: -36,
    left: '46%',
    width: 48,
    height: 'auto',
    padding: 8,
    borderRadius: 28,
    backgroundColor: 'white'
  },
  fieldInfoCircleMobile: {
    width: 'auto',
    height: 40,
    padding: theme.spacing(1)
  }
}));

interface FieldInfoProps {
  title?: string;
  subTitle?: string;
  marginLeft?: number;
  icon?: any;
  styles?: any;
  children?: any;
}

const FieldInfo: React.FC<FieldInfoProps> = React.memo(
  ({ title, subTitle, icon, marginLeft, styles, children }: FieldInfoProps) => {
    const classes = useStyles();

    return (
      <>
        <Hidden smDown>
          <Box
            marginLeft={marginLeft}
            style={styles}
            position="relative"
            width={280}
          >
            <Box className={classes.filedInfoContainer} marginLeft={3}>
              <Paper elevation={0} classes={{ root: classes.fieldInfoRoot }}>
                <img
                  src={DittoMascot}
                  className={classes.fieldInfoImg}
                  alt="Ditto"
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingX={2}
                  paddingY={4}
                >
                  {children ? (
                    children
                  ) : (
                    <>
                      <Typography variant="h6" color="textPrimary">
                        {title}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {subTitle}
                      </Typography>
                    </>
                  )}
                </Box>
              </Paper>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box marginTop={1}>
            <FieldInfoMobile subTitle={subTitle}>{children} </FieldInfoMobile>
          </Box>
        </Hidden>
      </>
    );
  }
);

FieldInfo.defaultProps = {
  marginLeft: 0
};

export { FieldInfo };
