import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import theme from '../../theme/theme';
import { useResponsive } from '../../theme/styles';
import { Check } from '@material-ui/icons';

const InsuranceConnector = withStyles({
  root: { border: 'none !important' },
  alternativeLabel: {
    top: 16,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    [theme.breakpoints.down('xs')]: {
      top: 8,
      left: 'calc(-50%)',
      right: 'calc(50%)'
    }
  },
  active: {
    '& $line': {
      borderColor: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        borderColor: theme.palette.grey[200]
      }
    }
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.grey[200]
    }
  },
  line: {
    borderColor: theme.palette.grey[200],
    borderTopWidth: 3,
    borderRadius: 1,
    [theme.breakpoints.down('xs')]: {
      borderTopWidth: 2,
      borderColor: '#efefef'
    }
  }
})(StepConnector);

const useInsuranceStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: '2px solid #D9DDDE',
    zIndex: 10,
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      width: 18,
      height: 18
    }
  },
  rootActive: {
    borderColor: theme.palette.primary.main
  },
  active: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: 'none',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: 10,
      height: 10
    }
  },
  progress: {},
  circle: {
    color: theme.palette.common.black,
    zIndex: 1,
    fontSize: '12px',
    width: 10,
    height: 10,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  completed: {
    color: theme.palette.common.white,
    zIndex: 1,
    fontSize: '12px',
    width: 10,
    height: 10,
    backgroundColor: '#D8EEFD',
    borderRadius: '50%',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  completedCheck: {
    fontSize: '12px'
  }
}));

function InsuranceStepIcon(props: StepIconProps) {
  const classes = useInsuranceStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(
        classes.root,
        active || completed ? classes.rootActive : null
      )}
    >
      {completed ? (
        <Check className={classes.completedCheck} color="primary" />
      ) : active ? (
        <div className={classes.active} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    stepperRoot: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
      }
    },
    label: {
      marginTop: theme.spacing(1),
      fontSize: '12px',
      color: theme.palette.text.primary,
      '&.MuiStepLabel-active': {
        fontWeight: 400
      },
      '&.MuiStepLabel-completed': {
        fontWeight: 400
      },
      '&.MuiStepLabel-alternativeLabel': {
        marginTop: theme.spacing(1)
      },
      '@media(max-width:375px)': {
        fontSize: '12px'
      }
    },
    labelActive: {
      fontSize: '14px',
      fontWeight: 400,
      '@media(max-width:375px)': {
        fontSize: '12px'
      }
    },
    stepRoot: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    },
    stepRootCompleted: {
      '& div': {
        border: `2px solid #D8EEFD`,
        background: '#D8EEFD'
      }
    }
  })
);

interface StepperProps {
  steps: string[];
  activeStep: number;
  hideLabels?: boolean;
  padForBackButton?: boolean;
}

const Stepper: React.FC<StepperProps> = React.memo(
  ({ steps, activeStep, hideLabels, padForBackButton }: StepperProps) => {
    const classes = useStyles();
    const r = useResponsive();

    return (
      <div
        className={classes.root}
        style={padForBackButton ? { marginLeft: r({ xs: 0, sm: 48 }) } : {}}
      >
        <MuiStepper
          alternativeLabel
          activeStep={activeStep}
          connector={<InsuranceConnector />}
          classes={{ root: classes.stepperRoot }}
        >
          {steps.map((label, index) => {
            return (
              <Step
                key={label}
                classes={{
                  root: classes.stepRoot,
                  completed: classes.stepRootCompleted
                }}
              >
                <StepLabel
                  StepIconComponent={InsuranceStepIcon}
                  icon={index}
                  classes={{
                    label: classes.label
                  }}
                >
                  {!hideLabels ? label : ''}
                </StepLabel>
              </Step>
            );
          })}
        </MuiStepper>
      </div>
    );
  }
);

export { Stepper };
