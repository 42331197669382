import React, { useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { findIndex } from 'lodash';
import Carousel from 'react-elastic-carousel';

import '../../theme/elastic-carousel.css';
import { useResponsive } from '../../theme/styles';
import { convertToINR } from '../../utils';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      width: 120,
      height: 40,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      textAlign: 'center',
      border: '1px solid #BFC4C6',
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
      borderRadius: 4,
      background: 'white',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      }
    },
    menuItemSelected: {
      border: '2px solid #3DABF5',
      fontWeight: 500,
      color: theme.palette.primary.main
    },
    loading: {
      background: '#FDFDFD',
      pointerEvents: 'none',
      cursor: 'not-allowed'
    },
    disabled: {
      background: '#F2F4F5',
      pointerEvents: 'none',
      cursor: 'not-allowed'
    }
  })
);

interface MenuItemProps {
  disabled: boolean;
  value: number;
  selected: boolean;
  type: 'age' | 'amount';
  loading?: boolean;
  onClick: (value: number) => void;
}

interface ElasticItemCarousel {
  items: number[];
  unavailableItems: number[];
  selected: number;
  type: 'amount' | 'age';
  loading?: boolean;
  onItemSelect: (value: number) => void;
}

const formatMenuItem = (value: number, type: 'age' | 'amount') => {
  if (type === 'amount') {
    return convertToINR(value, 2);
  } else {
    return `${value} yrs`;
  }
};

const MenuItem: React.FC<MenuItemProps> = ({
  value,
  selected,
  disabled,
  type,
  loading,
  onClick
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.menuItem,
        selected ? classes.menuItemSelected : null,
        loading ? classes.loading : null,
        disabled ? classes.disabled : null
      )}
      onClick={() => onClick(value)}
    >
      {formatMenuItem(value, type)}
    </div>
  );
};

const ElasticItemCarousel: React.FC<ElasticItemCarousel> = ({
  items,
  unavailableItems,
  selected,
  type,
  loading,
  onItemSelect
}) => {
  const r = useResponsive();
  const activeIndex = useRef<number>(findIndex(items, (o) => o === selected));
  const carouselRef = useRef<any>(null);
  const [localSelected, setLocalSelected] = useState<number>(selected);

  useEffect(() => {
    setLocalSelected(selected);
  }, [selected]);

  const handleItemSelection = (value: number) => {
    setLocalSelected(value);
    onItemSelect(value);
  };

  const canShowArrows = (): boolean => {
    const itemsToShow = r({ xs: 2, sm: 4, md: 4 }) || 0;
    const totalItems = items.length;
    return itemsToShow < totalItems;
  };

  return (
    <Carousel
      showArrows={canShowArrows()}
      itemsToShow={r({ xs: 2, sm: 4, md: 4 })}
      pagination={false}
      initialActiveIndex={activeIndex.current}
      focusOnSelect
      ref={carouselRef}
    >
      {items.map((item, index) => {
        return (
          <MenuItem
            key={index}
            value={item}
            selected={item === localSelected}
            disabled={unavailableItems.includes(item)}
            type={type}
            onClick={handleItemSelection}
            loading={loading}
          />
        );
      })}
    </Carousel>
  );
};

export { ElasticItemCarousel };
