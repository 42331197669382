import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormRow } from '../spacing';
import { MultiLabelCheckBox } from '.';
import { useResponsive } from '../../theme/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ValidationIcon } from './ValidationIcon';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  label: {
    fontWeight: 500
  }
}));

interface lableOption {
  label: string;
  labelRight?: string;
}

interface Option {
  name: string;
  labelOption: lableOption;
  isSelected?: boolean;
  onSelect?: (value: boolean) => void;
}

interface MultiSelectCheckBoxProps {
  label: string;
  label2: string;
  options: Option[];
  marginBottom?: number;
  smallButtons?: boolean;
  error?: string;
}

const MultiSelectCheckBoxes: React.FC<MultiSelectCheckBoxProps> = React.memo(
  ({
    label,
    label2,
    options,
    marginBottom,
    smallButtons,
    error
  }: MultiSelectCheckBoxProps) => {
    const classes = useStyles();
    const r = useResponsive();

    return (
      <div
        className={classes.textFieldContainer}
        style={
          marginBottom !== undefined || error
            ? { marginBottom: marginBottom }
            : undefined
        }
      >
        <Box marginBottom={1}>
          <Box marginBottom={2.5}>
            <Typography
              variant="body2"
              color="textSecondary"
              classes={{ root: classes.label }}
            >
              {label}
            </Typography>
          </Box>
          {/* <Typography variant="subtitle1">
            <Box component="span" fontWeight={400} color="#666666">
              {label2}
            </Box>
          </Typography> */}
        </Box>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={r({ md: 2, sm: 2, xs: 0 })}>
              <FormRow>
                {options.map((option: Option, index: number) => (
                  <MultiLabelCheckBox
                    key={index}
                    showTick
                    showRightLabel
                    leftAlignLabel
                    name={option.name}
                    option={option.labelOption}
                    isChecked={option.isSelected}
                    onChange={option.onSelect ? option.onSelect : undefined}
                    small={smallButtons}
                  />
                ))}
              </FormRow>
              {error && (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <ValidationIcon />
                  <FormHelperText error>{error}</FormHelperText>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export { MultiSelectCheckBoxes };
