import React from 'react';
import { Box, Dialog as MuiDialog, IconButton } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100% !important',
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1.2),
      [theme.breakpoints.only('sm')]: {
        marginBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2.4),
        paddingBottom: theme.spacing(2.4),
        paddingLeft: theme.spacing(1.6),
        paddingRight: theme.spacing(1.6),
        maxWidth: '100% !important',
        margin: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    paperBig: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 960
      }
    },
    paperSmall: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 437
      }
    },
    paperVerySmall: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 392,
        padding: 30
      }
    },
    paperMedium: {
      padding: theme.spacing(2.2),
      [theme.breakpoints.up('md')]: {
        maxWidth: 750
      }
    },
    noPadding: {
      padding: `${theme.spacing(0)} !important`
    },
    closeButton: {
      top: 23,
      right: 30,
      [theme.breakpoints.down('sm')]: {
        top: 16,
        right: 16
      },
      zIndex: 100000
    },
    closeButtomAutoWidth: {
      top: 0,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        top: -4,
        right: -10
      }
    },
    contentRoot: {
      height: '100%',
      overflow: 'hidden',
      padding: theme.spacing(0),
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      },
      '&:first-child': {
        paddingTop: 0
      }
    },
    closeIconButtonRoot: {
      width: 38,
      height: 38,
      padding: 11,
      [theme.breakpoints.down('xs')]: {
        width: 34,
        height: 34,
        padding: 9
      },
      background: '#F2F4F5'
    },
    iconRoot: {
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        fontSize: 13
      }
    }
  })
);

interface DialogProps {
  isShown: boolean;
  onClose: () => void;
  hasCloseButton?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  isVerySmall?: boolean;
  disableBackdropClick?: boolean;
  noPadding?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  children,
  isShown,
  isSmall,
  isMedium,
  isVerySmall,
  hasCloseButton,
  disableBackdropClick,
  noPadding,
  onClose
}) => {
  const classes = useStyles();

  return (
    <MuiDialog
      open={isShown}
      onClose={(event: any, reason: string) => {
        if (reason === 'backdropClick' && disableBackdropClick) {
          return;
        } else if (reason === 'escapeKeyDown' && disableBackdropClick) {
          return;
        }
        onClose();
      }}
      disableEscapeKeyDown={disableBackdropClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: clsx(
          classes.paper,
          !isSmall ? classes.paperBig : classes.paperSmall,
          isVerySmall ? classes.paperVerySmall : null,
          isMedium ? classes.paperMedium : null,
          noPadding ? classes.noPadding : null
        )
      }}
      scroll="body"
    >
      {hasCloseButton && (
        <Box position="absolute" className={clsx(classes.closeButton)}>
          <IconButton
            onClick={onClose}
            classes={{ root: classes.closeIconButtonRoot }}
          >
            {/* <CloseImg
              width={r({ xs: 34, sm: 34, md: 34 })}
              height={r({ xs: 34, sm: 34, md: 34 })}
            /> */}
            <CloseIcon classes={{ root: classes.iconRoot }} />
          </IconButton>
        </Box>
      )}
      <DialogContent classes={{ root: classes.contentRoot }}>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

export { Dialog };
