import React from 'react';
import {
  Grid,
  Button,
  Box,
  FormHelperText,
  Typography,
  Hidden,
  IconButton
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from 'formik';
import CloseIcon from '@material-ui/icons/Close';

import { BasicAutoComplete, BasicTextInput } from '.';
import { useResponsive } from '../../theme/styles';
import { Paper } from '../surfaces/Paper';
import { ValidationIcon } from './ValidationIcon';
import { FormContainer, FormRow } from '../spacing';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldLabelInput: {
    fontSize: 18,
    marginBottom: theme.spacing(1 / 2),
    marginTop: theme.spacing(1)
  },
  buttonRoot: {
    marginTop: theme.spacing(2)
  },
  removeButtonRoot: {
    marginTop: theme.spacing(1 / 2)
  },
  buttonLabel: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#F24C4C'
  },
  closeButtonRoot: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

interface AutoCompleteOption {
  value: string | number;
  label: string;
  customOption?: boolean | undefined;
}

interface Values {
  insurer: string | null;
  cover: number | undefined;
}

interface AddNewProps {
  addNewLabel: string;

  labelTextInput: string;
  labelAutoComplete: string;
  placeholderAutoComplete: string;
  options: AutoCompleteOption[];

  nameAutoComplete: string;
  nameTextInput: string;
  placeholderTextInput: string;
  typeNumber?: boolean;

  maxFields: number;
  disabledButton?: boolean;
  errorText?: string;

  values: Values[];

  onChange: (param1: any, param2: number, param3: string) => void;
  addNewField: () => void;
  removeField: (index: number) => void;
}

const AddNew: React.FC<AddNewProps> = (props) => {
  const {
    addNewLabel,
    options,
    nameAutoComplete,
    nameTextInput,
    disabledButton,
    typeNumber,
    placeholderTextInput,
    placeholderAutoComplete,
    maxFields,
    errorText,
    values,
    onChange,
    addNewField,
    removeField
  } = props;

  const classes = useStyles();
  const r = useResponsive();
  const [field, meta, helpers] = useField('otherInsuranceInfo');

  return (
    <Paper outerBoxProps={{ marginTop: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1">
            <Box component="span" fontWeight={600}>
              {addNewLabel}
            </Box>
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                onClick={addNewField}
                color="primary"
                variant="contained"
                disableElevation
                size="small"
                disabled={maxFields === values.length || disabledButton}
              >
                + Add More
              </Button>
            </Box>
          </Grid>
        </Hidden>
      </Grid>

      <React.Fragment>
        {[...values].map((elm: any, i: number) => {
          return (
            <FormContainer key={i}>
              <Hidden smUp>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={2}
                  width="100%"
                >
                  <Typography variant="body2">
                    <Box fontWeight={600}>Policy {i + 1}</Box>
                  </Typography>
                  {i !== 0 && (
                    <Button
                      onClick={() => removeField(i)}
                      variant="text"
                      size="small"
                      classes={{ label: classes.buttonLabel }}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              </Hidden>
              <FormRow>
                <Grid item xs={12} sm={5}>
                  <BasicAutoComplete
                    name={`${nameAutoComplete}-${i}`}
                    options={options}
                    value={values[i]['insurer']}
                    onBlur={() => helpers.setTouched(true)}
                    onChange={(val: any) => onChange(val, i, nameAutoComplete)}
                    placeholder={placeholderAutoComplete}
                    label={r({ sm: 'Select Insurer', xs: '' })}
                    marginTop={10}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <BasicTextInput
                    name={`${nameTextInput}-${i}`}
                    value={values[i]['cover']}
                    onBlur={() => helpers.setTouched(true)}
                    onChange={(val: any) => onChange(val, i, nameTextInput)}
                    typeNumber={typeNumber}
                    max={500000000}
                    placeholder={placeholderTextInput}
                    label={r({ sm: 'Cover amount', xs: '' })}
                    marginTop={10}
                  />
                </Grid>
                <Hidden xsDown>
                  {i !== 0 ? (
                    <Grid item sm={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        marginTop={2}
                        height="100%"
                      >
                        <IconButton
                          onClick={() => removeField(i)}
                          classes={{ root: classes.closeButtonRoot }}
                        >
                          <CloseIcon htmlColor="#F24C4C" />
                        </IconButton>
                      </Box>
                    </Grid>
                  ) : null}
                </Hidden>
              </FormRow>
            </FormContainer>
          );
        })}
        {meta.error && meta.touched && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop={1}
          >
            <ValidationIcon />
            <FormHelperText>
              {meta.error && meta.touched ? errorText : ''}
            </FormHelperText>
          </Box>
        )}
      </React.Fragment>
      <Hidden smUp>
        <Box marginTop={3}>
          <Button
            onClick={addNewField}
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            disabled={maxFields === values.length || disabledButton}
            fullWidth
          >
            + Add More
          </Button>
        </Box>
      </Hidden>
    </Paper>
  );
};

export { AddNew };
