import { FC } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { TableLabel } from '../widgets/TableLabel';
import { Typography } from '@material-ui/core';
import { Convert, Lead } from '../../types';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none'
    },
    head: {
      backgroundColor: '#efefef',
      borderBottom: '1px solid #dadadaa !important',
      color: theme.palette.common.black
    },
    body: {
      fontSize: 14
    }
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white
      }
    }
  })
)(TableRow);

// const rows = data;

const useStyles = makeStyles({
  table: {
    minWidth: 700
  },
  container: {
    maxHeight: '65vh'
  }
});

interface LeadsTableProps {
  type: 'Leads' | 'Converts';
  data: any;
}

const LeadsTable: FC<LeadsTableProps> = ({ type, data }) => {
  const classes = useStyles();

  const renderConverts = () => {
    return data.map((convert: Convert) => (
      <StyledTableRow key={convert.id}>
        <StyledTableCell component="td" align="center">
          <Box display="flex" flexDirection="row">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              textAlign="left"
            >
              <Typography variant="body1" color="textPrimary">
                {convert.full_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                ({convert.email})
              </Typography>
            </Box>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {convert.mobile}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            textAlign="center"
          >
            <Typography variant="body1" color="textPrimary">
              {convert.product}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              (Reassure)
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {convert.incentive}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {convert.logged_in_date}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <TableLabel label={convert.status} />
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  const renderLeads = () => {
    return data.map((lead: Lead) => (
      <StyledTableRow key={lead.id}>
        <StyledTableCell component="td" align="center">
          <Typography variant="body1" color="textPrimary">
            {lead.name}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {lead.email}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {lead.mobile}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" color="textPrimary">
            {lead.channel}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return data.length ? (
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {type === 'Converts' ? (
              <>
                <StyledTableCell align="left">User</StyledTableCell>
                <StyledTableCell align="center">Phone</StyledTableCell>
                <StyledTableCell align="center">Product</StyledTableCell>
                <StyledTableCell align="center">Premium</StyledTableCell>
                <StyledTableCell align="center">Payment Date</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </>
            ) : (
              <>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Phone</StyledTableCell>
                <StyledTableCell align="center">Channel</StyledTableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {type === 'Converts' ? renderConverts() : renderLeads()}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box
      bgcolor="white"
      padding={2}
      textAlign="center"
      fontSize={20}
      fontWeight={600}
      borderRadius={16}
    >
      No matching {type} found
    </Box>
  );
};

export { LeadsTable };
